import { Divider, Grid } from '@mui/material';
import { BdsButton, BdsGrid, BdsInput, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import TemplateCreatorInput from 'components/TemplateCreatorInput';
import React, { useRef, useState } from 'react';
import './style.scss';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import TemplatePreviewModal from 'components/TemplatePreviewModal';
import MainHeader from 'components/MainHeader';
import { useNavigate } from 'react-router-dom';
import useBlipGoApi from 'hooks/useBlipGoApi';
import { Template } from 'types/ActiveMessage';
import useToast from 'hooks/useToast';

function MessageCreation() {
  const [templateTextContent, setTemplateTextContent] = useState('');
  const [templateName, setTemplateName] = useState('');
  const isMobileView = useMobileView();
  const modalRef = useRef<HTMLBdsModalElement>(null);
  const navigate = useNavigate();
  const { createMessageTemplate } = useBlipGoApi();
  const { createToastSuccess, createToastError } = useToast();

  const replaceTemplateNameToWAPatterns = () => {
    // Replace invalid characters with their english alphabet equivalents and remove invalid symbols
    return templateName
      .replace(/ /g, '_')
      .replace(/[\s-]/g, '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  const replaceFormatTagsToWAPatterns = () => {
    return templateTextContent
      .replace(/<strong>|<\/strong>/g, '**')
      .replace(/<em>|<\/em>/g, '__')
      .replace(/<s>|<\/s>/g, '~~')
      .replace(/<p>|<\/p>/g, '')
      .replace(/&nbsp;/g, '');
  };

  const VariableExamplesTable = () => {
    return (
      <div className="template-variables-example">
        <BdsTypo variant="fs-12">telefone</BdsTypo>
        <BdsTypo variant="fs-12">variável 1</BdsTypo>
        <BdsTypo variant="fs-12">variável 2</BdsTypo>
        <BdsTypo variant="fs-12">+55419...</BdsTypo>
        <BdsTypo variant="fs-12">Maria José</BdsTypo>
        <BdsTypo variant="fs-12">Emprestimo</BdsTypo>
      </div>
    );
  };

  const countTemplateVariables = (): number => {
    const regex = /\{\{variavel(\d+)\}\}/g;
    return [...templateTextContent.matchAll(regex)].length;
  };

  const handleTemplateCreation = () => {
    if (countTemplateVariables() > 0) {
      modalRef?.current?.toggle();
    } else {
      createTemplate();
    }
  };

  const createTemplate = async (variableExamples?: string[]) => {
    const template: Template = {
      templateId: '',
      name: replaceTemplateNameToWAPatterns(),
      category: 'Marketing',
      language: 'pt-BR',
      status: '',
      body: {
        text: replaceFormatTagsToWAPatterns(),
        variablesExamples: variableExamples?.length ? [variableExamples] : [],
      },
      buttons: [],
    };
    try {
      await createMessageTemplate(template);
      createToastSuccess(
        'Mensagem cadastrada! Pendente de aprovação.',
        'O conteúdo da mensagem está sendo analisado pela Meta e será aprovado em alguns segundos, utilize o botão ”Atualizar” pra obter o status da análise.',
      );
      navigate('/campaign');
    } catch (e: any) {
      let errorMessage = 'Por favor, tente novamente mais tarde.';
      if (e?.data?.Message?.includes('There is already Portuguese')) {
        errorMessage = 'Já existe um modelo de mensagem criado com este nome!';
      }
      createToastError('Erro ao cadastrar mensagem', errorMessage);
    }
  };

  return (
    <BdsGrid direction="column" className="message-list-item-container">
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <BdsGrid justifyContent="center">
        <BdsGrid direction="column" gap="3" className="main-container">
          <MainHeader
            title="Nova mensagem"
            description={`Mensagens enviadas em massa devem ser cadastradas e aprovadas pelo WhatsApp, conforme <a href="
           https://developers.facebook.com/docs/whatsapp/message-templates/guidelines" target="_blank" rel="noopener noreferrer">diretrizes da Meta</a>.`}
            secondaryTitle="Mensagens ativas"
          />
          <BdsPaper className="new-message-container">
            <BdsGrid direction="column" gap="3">
              <BdsGrid direction="column" gap="1">
                <BdsTypo variant="fs-20" bold="bold" margin={false}>
                  Nome da mensagem
                </BdsTypo>
                <BdsTypo variant="fs-14">Defina um nome que será usado para identificação interna.</BdsTypo>
                <BdsInput
                  maxlength={30}
                  max="30"
                  counterLength
                  placeholder="Nome para identificação"
                  onBdsInput={(ev: any) => setTemplateName(ev?.target?.value)}
                />
              </BdsGrid>
              <Divider />
              <BdsTypo variant="fs-20" bold="bold" margin={false}>
                Conteúdo da mensagem
              </BdsTypo>
              <Grid container spacing={7}>
                <Grid item xs={12} lg={6}>
                  <BdsGrid direction="column">
                    <BdsGrid padding="3" className="template-input-container">
                      <TemplateCreatorInput textOutputEvent={setTemplateTextContent} />
                    </BdsGrid>
                  </BdsGrid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BdsGrid direction="column" gap="2">
                    <BdsTypo bold="bold" variant="fs-16">
                      Entenda as variáveis de texto
                    </BdsTypo>
                    <BdsTypo variant="fs-14">
                      Os parâmetros <b>{`{{ variavel }}`}</b> no texto serão preenchidos com dados fornecidos no momento
                      do envio ao cliente. Por exemplo, uma mensagem com duas variáveis poderia ser preenchida dessa
                      forma:
                    </BdsTypo>

                    <VariableExamplesTable />

                    <BdsGrid direction="column" gap="1" className="template-message-demo-container">
                      <BdsTypo variant="fs-14" className="template-message-demo-greyed-text">
                        Olá <b>{`{{ variavel1 }}`}</b>, vamos falar sobre o <b>{`{{ variavel2 }}`}</b>?
                      </BdsTypo>
                      <Divider />
                      <BdsTypo variant="fs-16">
                        Olá <b>Maria José</b>, vamos falar sobre o <b>empréstimo</b>?
                      </BdsTypo>
                    </BdsGrid>
                  </BdsGrid>
                </Grid>
              </Grid>

              <BdsGrid gap="2">
                <MaterialButton
                  variant="secondary"
                  text="Voltar"
                  onClickCallback={() => navigate('/campaign')}
                ></MaterialButton>
                <BdsButton onBdsClick={handleTemplateCreation} disabled={!templateName || !templateTextContent}>
                  Cadastrar nova mensagem
                </BdsButton>
              </BdsGrid>
            </BdsGrid>
          </BdsPaper>
        </BdsGrid>
      </BdsGrid>

      <TemplatePreviewModal ref={modalRef} messageContent={templateTextContent} onConclusion={createTemplate} />
    </BdsGrid>
  );
}

export default MessageCreation;
