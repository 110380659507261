import { BdsButton, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Breadcrumb({
  pageTexts,
  showInputFaq,
  resetFaqState,
}: {
  pageTexts: { headerTitle: string; pageTitle: string; text: string };
  showInputFaq: boolean;
  resetFaqState: () => void;
}) {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <BdsGrid alignItems="center" margin="b-3" padding="r-4" justifyContent="space-between" xxs="auto">
      <BdsGrid gap="1" alignItems="center">
        <BdsIcon class="cursor-pointer" onClick={navigateToDashboard} name="home" />
        <BdsTypo class="cursor-pointer" onClick={navigateToDashboard} variant="fs-14" bold="bold">
          Painel de Controle
        </BdsTypo>
        <BdsIcon name="arrow-right" />
        <BdsTypo class="cursor-pointer" onClick={() => resetFaqState()} variant="fs-14" bold="bold">
          Banco de Informações
        </BdsTypo>
        {showInputFaq && (
          <>
            <BdsIcon name="arrow-right" />
            <BdsTypo variant="fs-14" bold="bold">
              {pageTexts.headerTitle}
            </BdsTypo>
          </>
        )}
      </BdsGrid>
      <BdsGrid alignItems="center">
        <BdsIcon name="arrow-left" />
        <BdsButton onClick={showInputFaq ? () => resetFaqState() : navigateToDashboard} variant="secondary">
          Voltar
        </BdsButton>
      </BdsGrid>
    </BdsGrid>
  );
}

export default Breadcrumb;
