const HOST = process.env.REACT_APP_BLIPGO_API_URL;
const TENANT_ID = process.env.REACT_APP_PACK_TENANT;

export const GET_MESSAGE_TEMPLATES_ROUTE = (botId: string) =>
  `${HOST}/tenants/${TENANT_ID}/bots/${botId}/messages/templates?pageSize=100`;

export const CREATE_MESSAGE_TEMPLATES_ROUTE = (botId: string) =>
  `${HOST}/tenants/${TENANT_ID}/bots/${botId}/messages/templates`;

export const DISPATCH_WHATSAPP_CAMPAIGN_ROUTE = (botId: string) =>
  `${HOST}/tenants/${TENANT_ID}/bots/${botId}/messages/campaigns`;

export const DELEGATE_BOT_ROUTE = `${HOST}/delegation`;

export const GET_ROUTER_SKILLS_ROUTE = (botId: string) =>
  `${HOST}/tenants/${TENANT_ID}/bots/${botId}/bots/associated-with-router`;

export const GET_WABAS_ROUTE = (botId: string, metaAccessToken: string) =>
  `${HOST}/tenants/${TENANT_ID}/bots/${botId}/WhatsApp/business?accessToken=${metaAccessToken}`;

export const SET_STATE_CONFIGURATIONS = (botId: string) =>
  `${HOST}/tenants/${TENANT_ID}/bots/${botId}/WhatsApp/state-configurations`;
