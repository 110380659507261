// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-100 {
  width: 100% !important;
}

.bullet-list {
  margin: 0;
  padding-left: 1rem;
}

.bullet-list li:not(:last-child) {
  margin-bottom: 1rem;
}

.spotIconContainer {
  display: flex;
  align-self: flex-start;
}

.iconContainer {
  margin-right: 1rem;
  color: var(--color-delete, rgb(230, 15, 15)) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramSteps/InstagramAllowMessagesAccess/style.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;AADF;;AAGA;EACE,SAAA;EACA,kBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,uDAAA;AAAF","sourcesContent":["@use '/src/styles/colors';\r\n\r\n.w-100 {\r\n  width: 100% !important;\r\n}\r\n.bullet-list {\r\n  margin: 0;\r\n  padding-left: 1rem;\r\n}\r\n\r\n.bullet-list li:not(:last-child) {\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.spotIconContainer {\r\n  display: flex;\r\n  align-self: flex-start;\r\n}\r\n\r\n.iconContainer {\r\n  margin-right: 1rem;\r\n  color: colors.$color-delete !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
