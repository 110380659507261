import { BdsButton, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import GptSimulatorContainer from 'components/GptSimulator/GptSimulatorContatiner/GptSimulatorContainer';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import usePlgApi from 'hooks/usePlgApi';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BotStatus } from 'types/Bot';
import './style.scss';

function AssistantTester() {
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const { setBotStatus } = usePlgApi();

  const backToDashboard = async () => {
    await setBotStatus(BotStatus.Tested);
    navigate('/dashboard');
  };

  return (
    <>
      {!isMobileView && <Sidebar />}
      <BdsGrid justifyContent="center">
        <BdsGrid className="main-container" direction="column">
          {!isMobileView && (
            <BdsGrid alignItems="center" margin="b-3" justifyContent="space-between" xxs="auto">
              <BdsGrid gap="1" alignItems="center">
                <BdsIcon className="cursor-pointer" onClick={backToDashboard} name="home" />
                <BdsTypo className="cursor-pointer" onClick={backToDashboard} variant="fs-14" bold="bold">
                  Painel de Controle
                </BdsTypo>
                <BdsIcon name="arrow-right" />
                <BdsTypo variant="fs-14" bold="bold">
                  Teste seu assistente
                </BdsTypo>
              </BdsGrid>
              <BdsButton onClick={backToDashboard} variant="secondary">
                Voltar
              </BdsButton>
            </BdsGrid>
          )}
          <GptSimulatorContainer />
        </BdsGrid>
      </BdsGrid>
    </>
  );
}

export default AssistantTester;
