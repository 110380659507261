import BlipBallon from 'assets/images/blip-logo-balloon.svg';
import { BdsChipTag, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import NameInitials from 'components/NameInitials';
import SidebarItem from 'components/SidebarItem';
import { VERSION } from 'libs/app';
import { COEX_MODULE_ADS } from 'libs/trackingEvents';
import React from 'react';
import './style.scss';

function Sidebar() {
  return (
    <div className="sidebar">
      <BdsGrid
        gap="4"
        alignItems="center"
        direction="column"
        justifyContent="space-between"
        height="100%"
        padding="y-4"
      >
        <BdsGrid gap="1" alignItems="center" direction="column" justifyContent="center">
          <img className="blip-balloon-logo" alt="Blip logo" src={BlipBallon} />
          <BdsChipTag color="outline">COEX</BdsChipTag>
        </BdsGrid>

        <BdsGrid gap="2" alignItems="center" direction="column" justifyContent="center">
          <SidebarItem route="/dashboard" tooltip="Home" icon="home" />
          <SidebarItem route="/ads" trackingName={COEX_MODULE_ADS} tooltip="Gerenciador de anúncios" icon="target" />
          <SidebarItem route="/campaign" trackingName={''} tooltip="Mensagens ativas" icon="message-sent" />
        </BdsGrid>
        <BdsGrid direction="column" gap="1">
          <NameInitials />
          <BdsTypo variant="fs-12">v{VERSION}</BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </div>
  );
}

export default Sidebar;
