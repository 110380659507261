import { BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import AudienceFileUpload from 'components/AudienceFileUpload';
import parse from 'html-react-parser';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Template } from 'types/ActiveMessage';
import './style.scss';

function MessageConfiguration() {
  const locationState = useLocation();
  const selectedTemplate: Template = locationState.state.template;
  const hasVariables = !!selectedTemplate.body.variablesExamples.length;

  const htmlParsedString = selectedTemplate.body.text
    .replace(/\\n+/g, match => '<br>'.repeat(match.length / 2))
    .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
    .replace(/\{\{(\d+)\}\}/g, (_, number) => `<strong>{{variavel${number}}}</strong>`)
    .replace(/_(.*?)_/g, '<em>$1</em>')
    .replace(/~(.*?)~/g, '<del>$1</del>');

  return (
    <BdsPaper style={{ backgroundColor: 'white' }}>
      <BdsGrid direction="column" padding="4" gap="2">
        <BdsTypo variant="fs-20" tag="h4" bold="bold">
          Pré-visualização da mensagem
        </BdsTypo>
        <BdsGrid gap="4" md="8" lg="8" xg="8">
          <BdsTypo className="template-text-container">
            <span>{parse(htmlParsedString)}</span>
          </BdsTypo>
          {hasVariables && (
            <BdsGrid direction="column" gap="2">
              <BdsTypo bold="bold">Entenda as variáveis de texto</BdsTypo>
              <BdsTypo>
                Os espaços reservados com <b>{'{{variavel}}'}</b> no texto serão preenchidos com os dados fornecidos no
                aquivo excel xlsx. Como por exemplo:
              </BdsTypo>
              <div className="grid-example">
                <BdsTypo variant="fs-12">telefone</BdsTypo>
                <BdsTypo variant="fs-12">variável 1</BdsTypo>
                <BdsTypo variant="fs-12">variável 2</BdsTypo>
                <BdsTypo variant="fs-12">+554198...</BdsTypo>
                <BdsTypo variant="fs-12">Nome Pessoa</BdsTypo>
                <BdsTypo variant="fs-12">Nome Produto</BdsTypo>
              </div>
              <BdsGrid alignItems="center" gap="1">
                <BdsIcon size="x-small" name="info" />
                <BdsTypo variant="fs-12">Todas as variáveis devem ser preenchidas.</BdsTypo>
              </BdsGrid>
            </BdsGrid>
          )}
        </BdsGrid>
        <BdsGrid direction="column" padding="y-2">
          <div className="horizontal-line" />
        </BdsGrid>
        <AudienceFileUpload />
      </BdsGrid>
    </BdsPaper>
  );
}

export default MessageConfiguration;
