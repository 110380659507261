import {
  BdsButton,
  BdsChipTag,
  BdsGrid,
  BdsLoadingSpinner,
  BdsPaper,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import useBlipGoApi from 'hooks/useBlipGoApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import parse from 'html-react-parser';
import { COEX_CAMPAIGN_SELECT_TEMPLATE, COEX_REFRESH_ACITVE_MESSAGES } from 'libs/trackingEvents';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Template } from 'types/ActiveMessage';
import './style.scss';
import { ACTIVE_MESSAGES_REJECT_REASON } from 'libs/templatesErrors';
import MaterialButton from 'components/UI/MaterialButton';
import { MessageTemplateStatus } from 'types/MessageTemplate';
import { Divider } from '@mui/material';
import { useMobileView } from 'contexts/MobileViewContext';

function MessageTemplates() {
  const { getMessagesTemplates } = useBlipGoApi();
  const [messageTemplates, setMessageTemplates] = useState<Template[]>([]);
  const { createToastError } = useToast();
  const { track } = useTrack();
  const navigate = useNavigate();
  const isMobileView = useMobileView();
  const [allowReload, setAllowReload] = useState(false);

  function hasPendingStatus(templates: Template[]) {
    const hadPendingTemplates = templates.some(item => item.status === MessageTemplateStatus.Pending);
    setAllowReload(hadPendingTemplates);
  }

  const loadTemplates = async () => {
    setMessageTemplates([]);
    try {
      const res = await getMessagesTemplates();
      setMessageTemplates(res);
      hasPendingStatus(res);
      if (!res.length) {
        navigate('/templates/empty');
      }
    } catch {
      createToastError(
        'Erro ao carregar os templates de mensagem',
        'Por favor, verifique se existem templates cadastrados',
      );
      navigate('/templates/empty');
    }
  };

  const handleTemplateClick = (template: Template) => {
    track(COEX_CAMPAIGN_SELECT_TEMPLATE);
    navigate('../configuration', { state: { template } });
  };

  const handleRefreshMessages = () => {
    track(COEX_REFRESH_ACITVE_MESSAGES);
    loadTemplates();
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  if (!messageTemplates?.length) {
    return (
      <BdsGrid justifyContent="center">
        <BdsLoadingSpinner data-testid="loading-spinner" />
      </BdsGrid>
    );
  }

  return (
    <BdsGrid direction="column" gap="4">
      <BdsGrid direction={isMobileView ? 'column' : 'row'} gap="3" justifyContent="space-between">
        {isMobileView && <Divider />}
        <BdsTypo tag="h4" variant="fs-20" bold="bold" margin={false}>
          Use uma mensagem pré-aprovada para fazer o disparo
        </BdsTypo>
        <BdsGrid gap="2">
          <BdsButton
            variant="outline"
            color="content"
            onBdsClick={handleRefreshMessages}
            icon="refresh"
            disabled={!allowReload}
          >
            Atualizar
          </BdsButton>

          <BdsButton onBdsClick={() => navigate('/campaign-template-create')}>Criar mensagem</BdsButton>
        </BdsGrid>
      </BdsGrid>
      <BdsGrid direction="column" gap="1">
        {messageTemplates.map((template, index) => {
          return (
            <TemplateListItem
              key={index + 1}
              id={template.templateId}
              text={template.body?.text}
              status={template.status}
              rejectedReasonCode={template.rejectedReason}
              onTemplateClick={() => handleTemplateClick(template)}
            />
          );
        })}
      </BdsGrid>
    </BdsGrid>
  );
}

type ITemplateListItemProps = {
  id: string;
  text: string;
  status: MessageTemplateStatus;
  rejectedReasonCode?: string;
  onTemplateClick: () => void;
};

const TemplateListItem = ({ id, text, onTemplateClick, status, rejectedReasonCode }: ITemplateListItemProps) => {
  const isMobileView = useMobileView();
  const parsedText = text
    .replace(/\\n+/g, match => '<br>'.repeat(match.length / 2))
    .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
    .replace(/\{\{(\d+)\}\}/g, (_, number) => `<strong>{{variavel${number}}}</strong>`)
    .replace(/_(.*?)_/g, '<em>$1</em>')
    .replace(/~(.*?)~/g, '<del>$1</del>');

  const rejectionReason = ACTIVE_MESSAGES_REJECT_REASON.find(reason => reason.REJECT_REASON === rejectedReasonCode);

  return (
    <BdsPaper elevation="static">
      <BdsGrid
        justifyContent="space-between"
        direction={isMobileView ? 'column' : 'row'}
        gap="2"
        className="template-list-item-container"
      >
        <BdsGrid direction="column" className="template-list-name-container">
          <BdsTypo className="template-list-name" bold="bold">
            Nome da mensagem
          </BdsTypo>
          <span>{id}</span>
        </BdsGrid>

        <BdsGrid direction="column" className="template-list-message-preview-container">
          <BdsTypo className="template-list-name" bold="bold">
            Prévia do conteúdo
          </BdsTypo>
          <BdsTypo>{parse(parsedText)}</BdsTypo>
        </BdsGrid>
        <BdsGrid justifyContent="center" alignItems="center">
          {status === MessageTemplateStatus.Approved ? (
            <MaterialButton
              text="Usar mensagem"
              endIconBdsName="arrow-right"
              variant="secondary"
              onClickCallback={onTemplateClick}
              isMobileView={isMobileView}
              data-testid={`template-${id}`}
            />
          ) : (
            <BdsTooltip
              id="tooltip"
              position="top-center"
              tooltip-text={
                status === MessageTemplateStatus.Pending ? 'Aguardando aprovação do WhatsApp' : rejectionReason?.MESSAGE
              }
            >
              <BdsChipTag
                color={status === MessageTemplateStatus.Pending ? 'warning' : 'danger'}
                icon={status === MessageTemplateStatus.Pending ? 'clock' : 'delete'}
              >
                {status === MessageTemplateStatus.Pending ? 'Em análise' : 'Reprovada'}
              </BdsChipTag>
            </BdsTooltip>
          )}
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};

export default MessageTemplates;
