import { BdsLoadingPage } from 'blip-ds/dist/blip-ds-react/components';
import { useAppContext } from 'contexts/AppContext';
import useBlipGoApi from 'hooks/useBlipGoApi';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function AsyncPreparation() {
  const navigate = useNavigate();
  const { botConfiguration } = useAppContext();
  const { delegateBot } = useBlipGoApi();

  useEffect(() => {
    if (botConfiguration?.botId) {
      delegateBot(botConfiguration.botId);
      return navigate('/dashboard');
    } else {
      navigate('/onboarding/company-form');
    }
  }, []);

  return <BdsLoadingPage />;
}

export default AsyncPreparation;
