import { BdsGrid, BdsPaper } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import { FacebookCatalogSteps } from 'libs/facebookCatalogSteps';
import React, { useEffect, useState } from 'react';
import FacebookCatalogConnection from '../FacebookCatalogSteps/FacebookCatalogConnection';
import FacebookCatalogLogin from '../FacebookCatalogSteps/FacebookCatalogLogin';
import FacebookCatalogNoOrganizationFound from '../FacebookCatalogSteps/FacebookCatalogNoOrganizationFound';
import FacebookCatalogSwitchProfile from '../FacebookCatalogSteps/FacebookCatalogSwitchProfile';
import './style.scss';

function FacebookCatalogContainer() {
  const isMobileView = useMobileView();
  const { getFacebookConnectedData } = useGraphApi();
  const [currentStep, setCurrentStep] = useState(FacebookCatalogSteps.FacebookCatalogLogin);

  const getStepContentByIndex = (index: number) => {
    return steps.find(step => step.index === index)?.content;
  };

  useEffect(() => {
    const fbConnectedData = getFacebookConnectedData();
    if (!fbConnectedData || fbConnectedData.scope !== 'catalog_connection') {
      setCurrentStep(FacebookCatalogSteps.FacebookCatalogLogin);
    }
  }, [getFacebookConnectedData]);

  const steps = [
    {
      index: FacebookCatalogSteps.FacebookCatalogLogin,
      label: 'Facebook catálogo login',
      content: <FacebookCatalogLogin setCurrentStep={setCurrentStep} />,
    },
    {
      index: FacebookCatalogSteps.FacebookCatalogSwitchProfile,
      label: 'Lista de páginas comerciais do Facebook',
      content: <FacebookCatalogSwitchProfile setCurrentStep={setCurrentStep} />,
    },
    {
      index: FacebookCatalogSteps.FacebookCatalogConnection,
      label: 'Conectar catálogo do Facebook',
      content: <FacebookCatalogConnection setCurrentStep={setCurrentStep} />,
    },
    {
      index: FacebookCatalogSteps.FacebookCatalogNoOrganizationFound,
      label: 'Organização não encontrada',
      content: <FacebookCatalogNoOrganizationFound setCurrentStep={setCurrentStep} />,
    },
  ];

  return (
    <BdsPaper className={isMobileView ? 'p-size' : undefined}>
      <BdsGrid justifyContent="center" alignItems="center" gap="3" padding={!isMobileView ? '5' : '2'}>
        {getStepContentByIndex(currentStep)}
      </BdsGrid>
    </BdsPaper>
  );
}
export default FacebookCatalogContainer;
