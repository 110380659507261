import { firstDashboardVisit, whatsappActive } from 'atoms/AppAtoms';
import { BdsGrid, BdsLoadingPage } from 'blip-ds/dist/blip-ds-react/components';
import CatalogPaperCard from 'components/CatalogPaperCard';
import DashboardHeader from 'components/DashboardHeader';
import FaqCard from 'components/FAQ/FaqCard';
import FaqInputContinueModal from 'components/FaqWizard/FaqWizardInput/FaqInputNew/FaqInputContinueModal';
import InstallationErrorHeader from 'components/InstallationErrorHeader';
import MainHeader from 'components/MainHeader';
import MobileToolbar from 'components/MobileToolbar';
import SetupAssistantBanner from 'components/SetupAssistantBanner';
import Sidebar from 'components/Sidebar';
import WhatsappConnectionCard from 'components/WhatsapppConectionCard/WhatsapppConectionCard';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import usePacksApi from 'hooks/usePacksApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { useAtom } from 'jotai';
import { COEX_CONNECTION_START, INSTALLATION, OPENED } from 'libs/trackingEvents';
import { useAuth } from 'oidc-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const { botConfiguration, installation } = useAppContext();
  const [firstVisit, setFirstVisit] = useAtom(firstDashboardVisit);
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const isMobileView = useMobileView();
  const { pollInstallationStatusWithRetries } = usePacksApi();
  const { track } = useTrack();
  const navigate = useNavigate();
  const { getIsWhatsAppActivated } = usePacksApi();
  const [isWhatsappConnected, setIsWhatsappConnected] = useAtom(whatsappActive);
  const { createToastError } = useToast();
  const { userData } = useAuth();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;
  const [isLoading, setIsLoading] = useState(true);

  const pollInstallationStatus = async (botId: string) => {
    if (installation.statusProcess?.status === 'Completed') return;
    try {
      await pollInstallationStatusWithRetries(botId);
    } catch (error) {
      track(INSTALLATION, { status: 'Error', error: error });
      setShowErrorHeader(true);
    }
  };

  const loadWhatsappStatus = async () => {
    try {
      const whatsappStatus = await getIsWhatsAppActivated(botConfiguration.botId);
      setIsWhatsappConnected(whatsappStatus);
    } catch (error) {
      createToastError('Não foi possível carregar informações do seu WhatsApp', 'Tente novamente mais tarde');
    } finally {
      setIsLoading(false);
      setFirstVisit(false);
    }
  };

  useEffect(() => {
    if (firstVisit) {
      track(OPENED);
      if (!botConfiguration?.botId) {
        return navigate('/');
      }
      pollInstallationStatus(botConfiguration.botId);

      loadWhatsappStatus();
    }
  }, []);

  const handleStartConectionClick = () => {
    track(COEX_CONNECTION_START);
    navigate('/whatsapp/number-check');
  };

  if (isLoading && firstVisit) {
    return <BdsLoadingPage />;
  }

  return (
    <>
      {showErrorHeader && <InstallationErrorHeader hideError={() => setShowErrorHeader(false)} />}
      {
        <>
          {isMobileView ? <MobileToolbar /> : <Sidebar />}
          <BdsGrid justifyContent="center">
            <BdsGrid direction="column" className="main-container" gap="3">
              <MainHeader
                title={`Boas vindas, ${userName}`}
                description="Gerencie o seu atendimento e campanhas com o Blip Go Coex!"
              />
              {isWhatsappConnected ? (
                <BdsGrid xs="auto" lg="12" md="12" direction="column" gap="3">
                  <BdsGrid sm="12" md="4" margin="none" padding="half">
                    <DashboardHeader />
                  </BdsGrid>
                  <BdsGrid
                    xs="auto"
                    lg="auto"
                    md="8"
                    gap="3"
                    direction={!isMobileView ? 'row' : 'column'}
                    padding="half"
                    margin="none"
                  >
                    <FaqCard />
                    <CatalogPaperCard />
                  </BdsGrid>
                </BdsGrid>
              ) : (
                <>
                  <WhatsappConnectionCard
                    description="Com a conexão ativa, você poderá usar nosso assistente com IA, e iremos fornecer dados para te ajudar a atender melhor e aumentar as vendas."
                    onActionClick={handleStartConectionClick}
                    statusType="warning"
                    statusName="Importante"
                    title="Conecte ao WhatsApp Business"
                  />
                  <SetupAssistantBanner />
                </>
              )}
            </BdsGrid>
          </BdsGrid>
          <FaqInputContinueModal amountQuestions={5} />
        </>
      }
    </>
  );
}

export default Dashboard;
