import { BdsGrid, BdsIllustration, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import React from 'react';
import styles from './EmptyTemplateCard.module.scss';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_REGISTER_NEW_TEMPLATE } from 'libs/trackingEvents';

function EmptyTemplateCard() {
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const { track } = useTrack();

  const handleConfirmClick = async () => {
    track(COEX_REGISTER_NEW_TEMPLATE);
    navigate('/campaign-template-create');
  };

  return (
    <BdsPaper class="paper-pattern">
      <BdsGrid direction="column" justifyContent="center" padding="6" alignItems="center" gap="3">
        <BdsIllustration className={styles.illustration} type="spots" name="empty"></BdsIllustration>
        <BdsGrid direction="column" alignItems="center" md="8" className={styles.textCentered} gap="1">
          <BdsTypo variant="fs-20" bold="bold" margin={false}>
            Nenhuma mensagem cadastrada
          </BdsTypo>
          <BdsTypo variant="fs-16" margin={false}>
            Para fazer disparo de mensagens ativas, é necessário cadastrar uma mensagem que passará pela aprovação de
            conteúdo
          </BdsTypo>
        </BdsGrid>
        <MaterialButton
          onClickCallback={() => handleConfirmClick()}
          text="Cadastrar nova mensagem"
          variant="primary"
          isMobileView={isMobileView}
          cssVariant="w-fit"
        ></MaterialButton>
      </BdsGrid>
    </BdsPaper>
  );
}

export default EmptyTemplateCard;
