import {
  BdsAccordion,
  BdsAccordionBody,
  BdsAccordionHeader,
  BdsAvatar,
  BdsButton,
  BdsChipTag,
  BdsGrid,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import useTrack from 'hooks/useTrack';
import { InstagramSteps } from 'libs/instagramSteps';
import { CONNECT_INSTAGRAM_CHANGED_ACCOUNT } from 'libs/trackingEvents';
import React from 'react';
import './style.scss';

function FacebookConectedAccount({ setCurrentStep }: { setCurrentStep: (step: InstagramSteps) => void }) {
  const { getFacebookConnectedData } = useGraphApi();
  const fbConnectedData = getFacebookConnectedData();
  const isMobileView = useMobileView();
  const { track } = useTrack();

  const redirectTologout = () => {
    track(CONNECT_INSTAGRAM_CHANGED_ACCOUNT);
    setCurrentStep(InstagramSteps.InstagramSwitchFacebookProfile);
  };

  return isMobileView ? (
    <BdsGrid xxs="12" direction="row" gap="2" alignItems="flex-start">
      <BdsAccordion className="connected-account-container-mobile">
        <BdsAccordionHeader>
          <BdsGrid direction="row" gap="2">
            <BdsAvatar id="avatar" name={fbConnectedData?.name} size="micro" />
            <BdsTypo variant="fs-12" bold="bold" lineHeight="double">
              {fbConnectedData?.name}
            </BdsTypo>
          </BdsGrid>
        </BdsAccordionHeader>
        <BdsAccordionBody>
          <BdsGrid direction="column" alignItems="center">
            <BdsButton onClick={redirectTologout} variant="secondary">
              Trocar conta
            </BdsButton>
          </BdsGrid>
        </BdsAccordionBody>
      </BdsAccordion>
    </BdsGrid>
  ) : (
    <BdsGrid gap="2" className="connected-account-container" direction="column">
      <BdsGrid direction="column" alignItems="center">
        <BdsChipTag color="outline" icon="facebook">
          Conta conectada:
        </BdsChipTag>
      </BdsGrid>
      <BdsGrid direction="column" gap="1" alignItems="center">
        <BdsAvatar id="avatar" name={fbConnectedData?.name} size="extra-large"></BdsAvatar>
        <BdsTypo variant="fs-16" bold="bold">
          {fbConnectedData?.name}
        </BdsTypo>
      </BdsGrid>
      <BdsGrid direction="column" margin="t-2" alignItems="center">
        <BdsButton onClick={redirectTologout} variant="secondary">
          Trocar conta
        </BdsButton>
      </BdsGrid>
    </BdsGrid>
  );
}

export default FacebookConectedAccount;
