import React from 'react';
import './styles.scss';
import { BdsAvatar, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { useAuth } from 'oidc-react';
import { COEX_HELP_CLICKED, LOGOUT } from 'libs/trackingEvents';
import useTrack from 'hooks/useTrack';
import { useLocation } from 'react-router-dom';

interface IUserCardProps {
  backgroundColor: 'white' | 'grey';
}

function UserCard({ backgroundColor }: Readonly<IUserCardProps>) {
  const userName = useAuth().userData?.profile.FullName as string;
  const userEmail = useAuth().userData?.profile.email as string;
  const { signOut } = useAuth();
  const { track } = useTrack();
  const location = useLocation();

  const handleLinkClick = () => {
    track(COEX_HELP_CLICKED, { screenName: location.pathname });
    const coexLink =
      'https://api.whatsapp.com/send/?phone=553192670115&text=Ol%C3%A1,%20estou%20com%20d%C3%BAvidas%20sobre%20o%20Blip%20Go!%20COEX%20Beta';
    window.open(coexLink, '_blank');
  };

  const logout = async () => {
    track(LOGOUT);
    const urlRedirect = window.location.href;
    const urlEncoded = encodeURI(urlRedirect);
    const urlLogout = `${process.env.REACT_APP_AUTHORITY}/logout/redirect?returnDesiredUrl=${urlEncoded}`;
    await signOut();
    window.location.href = urlLogout;
  };

  return (
    <BdsGrid direction="column" padding="y-1" className={`user-card-container ${backgroundColor}`}>
      <BdsGrid gap="2" padding="2">
        <BdsAvatar size="small" name={userName} />
        <BdsGrid direction="column">
          <BdsTypo variant="fs-14" bold="bold">
            {userName}
          </BdsTypo>
          <BdsTypo variant="fs-12" className="user-email">
            {userEmail}
          </BdsTypo>
        </BdsGrid>
      </BdsGrid>

      <BdsGrid direction="column">
        <div className="horizontal-line" />
        <BdsGrid padding="1" gap="1" onClick={handleLinkClick} className="cursor-pointer user-card-action">
          <BdsIcon name="message-ballon" />
          <BdsTypo variant="fs-14" bold="bold">
            Acionar suporte
          </BdsTypo>
        </BdsGrid>
        <BdsGrid padding="1" gap="1" onClick={logout} className="cursor-pointer  user-card-action">
          <BdsIcon name="logout" />
          <BdsTypo variant="fs-14" bold="bold">
            Desconectar
          </BdsTypo>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
}

export default UserCard;
