import SetupBanner from 'assets/images/setup-assistant.png';
import { BdsButton, BdsChipTag, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import useTrack from 'hooks/useTrack';
import { COEX_SETUP_ASSISTANT } from 'libs/trackingEvents';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function SetupAssistantBanner() {
  const navigate = useNavigate();
  const { track } = useTrack();

  const handleSetupAssistant = () => {
    track(COEX_SETUP_ASSISTANT);
    navigate('/faq-wizard');
  };

  return (
    <BdsPaper elevation="none" style={{ backgroundColor: 'white' }}>
      <BdsGrid gap="3" padding="2" alignItems="center">
        <BdsGrid direction="column" gap="2" padding="l-2">
          <BdsGrid gap="1" alignItems="center">
            <BdsTypo variant="fs-20" tag="h4" bold="bold" margin={false}>
              Assistente Virtual
            </BdsTypo>
            <BdsChipTag color="disabled">Desativado</BdsChipTag>
          </BdsGrid>
          <BdsTypo>
            Tenha uma ajuda extra configurando o assistente para realizar o atendimento inicial e responder dúvidas com
            base nas informações fornecidas sobre seu negócio.
          </BdsTypo>
          <BdsButton onBdsClick={handleSetupAssistant} icon="settings-builder" color="content">
            Configurar assistente virtual
          </BdsButton>
        </BdsGrid>
        <img style={{ height: '16rem' }} src={SetupBanner} alt="Conversa de WhatsApp com empresa" />
      </BdsGrid>
    </BdsPaper>
  );
}

export default SetupAssistantBanner;
