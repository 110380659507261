import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import MobileToolbar from 'components/MobileToolbar';
import { useMobileView } from 'contexts/MobileViewContext';
import React, { useEffect, useState } from 'react';
import MainHeader from 'components/MainHeader';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import WhatsappConnectionContainer from 'components/WhatsappConnectionContainer';
import WhatsappConnectionMobile from 'components/WhatsappConnectionMobile';
import MainContainer from 'components/MainContainer';

function WhatsAppDenyMobileConnection() {
  const isMobileView = useMobileView();
  const { userData } = useAuth();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobileView) {
      navigate('/dashboard');
    }
  }, [isMobileView]);

  return (
    <>
      <MobileToolbar />
      <MainContainer>
        <MainHeader title={`Boas vindas, ${userName}`} description="" />
        <BdsGrid padding="2">
          <WhatsappConnectionContainer>
            <WhatsappConnectionMobile />
          </WhatsappConnectionContainer>
        </BdsGrid>
      </MainContainer>
    </>
  );
}

export default WhatsAppDenyMobileConnection;
