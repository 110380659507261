import App from 'App';
import { JSX as LocalJSX, defineCustomElements } from 'blip-ds/loader';
import React, { DetailedHTMLProps, HTMLAttributes, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

type StencilToReactElements<T = LocalJSX.IntrinsicElements> = {
  [P in keyof T]?: T[P] &
    Omit<DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>, 'className'> & {
      class?: string;
    };
};
type StencilToReactRef<T = HTMLElementTagNameMap> = {
  [P in keyof T]: {
    ref?: DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>['ref'];
  };
};
type StencilToReact<T = LocalJSX.IntrinsicElements, U = HTMLElementTagNameMap> = StencilToReactElements<T> &
  StencilToReactRef<U>;
declare global {
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact {}
  }
}
defineCustomElements(window);

const analyticsWriteKey = process.env.REACT_APP_ANALYTICS_KEY;
if (analyticsWriteKey) {
  analytics.load(analyticsWriteKey);
  analytics.page();
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
