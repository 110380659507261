import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsChipTag,
  BdsGrid,
  BdsIcon,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { FAQ_NEWFAQ_CLOSED, FAQ_NEWFAQ_CLOSE_CONFIRMED } from 'libs/trackingEvents';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BotAvatar from '../BotAvatar';

type FaqHeaderProps = {
  questionName: string;
  typeQuestion: string;
};

function FaqWizardHeader({ questionName, typeQuestion }: FaqHeaderProps) {
  const navigate = useNavigate();
  const { track } = useTrack();
  const isMobileView = useMobileView();
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleCloseWizardClick = () => {
    track(FAQ_NEWFAQ_CLOSED, { typeQuestion, questionName });
    setShowAlertModal(true);
  };

  const closeWizard = () => {
    track(FAQ_NEWFAQ_CLOSE_CONFIRMED, { typeQuestion, questionName });
    navigate('/dashboard');
  };

  return (
    <BdsGrid alignItems="center" className="header_container" padding="y-1">
      <BdsGrid padding="x-3" direction="row" gap="2" lg="12" xg="12" md="12" xxs="8">
        <BdsGrid alignItems="center" justifyContent="space-between" lg="12" xg="12" md="12">
          <BdsGrid alignItems="center" gap="2">
            <BotAvatar />
            <BdsTypo variant="fs-16" bold="bold" lineHeight="plus">
              Assistente Virtual
            </BdsTypo>
            <BdsChipTag color="outline">desativado</BdsChipTag>
          </BdsGrid>
          {!isMobileView && (
            <BdsButton onBdsClick={handleCloseWizardClick} variant="text" color="content">
              Cancelar
            </BdsButton>
          )}
        </BdsGrid>
      </BdsGrid>
      {isMobileView && (
        <BdsGrid xxs="4" padding="r-3" justifyContent="flex-end">
          <BdsIcon className="cursor-pointer" onClick={handleCloseWizardClick} name="close" type="icon" size="large" />
        </BdsGrid>
      )}
      <BdsAlert open={showAlertModal}>
        <BdsAlertHeader variant="warning" icon="warning">
          Interromper configuração de assuntos?
        </BdsAlertHeader>
        <BdsAlertBody>Seu assistente só ficará ativo após concluir todos os passos.</BdsAlertBody>
        <BdsAlertActions>
          <BdsButton onClick={() => setShowAlertModal(false)} variant="secondary">
            Continuar aqui
          </BdsButton>
          <BdsButton onClick={closeWizard} variant="secondary">
            Voltar à home
          </BdsButton>
        </BdsAlertActions>
      </BdsAlert>
    </BdsGrid>
  );
}

export default FaqWizardHeader;
