import { BotStatus } from 'types/Bot';

export const PLG_API_BASE_URL = process.env.REACT_APP_PLG_API_HOST;
export const PATCH_CATALOG = `${PLG_API_BASE_URL}/catalogs`;
export const GET_CATALOG = (botId: string) => `${PLG_API_BASE_URL}/catalogs/${botId}`;
export const PATCH_FAQ = `${PLG_API_BASE_URL}/faqs`;
export const GET_FAQ = (botId: string) => `${PLG_API_BASE_URL}/faqs/${botId}`;
export const BOT_CONFIGURATIONS = `${PLG_API_BASE_URL}/bot-configurations`;
export const GET_BOT_CONFIGURATIONS = (botId: string) => `${BOT_CONFIGURATIONS}/${botId}`;
export const SET_BOT_STATUS = (botId: string, status: BotStatus) => `${BOT_CONFIGURATIONS}/status/${botId}/${status}`;
export const SET_CHANNEL = `${PLG_API_BASE_URL}/channel`;
export const GET_CHANNEL = (botId: string) => `${PLG_API_BASE_URL}/channel/${botId}`;
export const SEND_ACTIVE_MESSAGE = `${PLG_API_BASE_URL}/send-active-message`;
export const GET_PROMPT_BY_BOT_ID = (botId: string) => `${PLG_API_BASE_URL}/prompts/${botId}/content`;
export const OPENAI_CHAT = `${PLG_API_BASE_URL}/openai-chat`;
export const SET_SUBSCRIPTION = `${PLG_API_BASE_URL}/subscriptions`;
export const GET_SUBSCRIPTION_BY_SUBSCRIPTION = (subscriptionId: string) =>
  `${PLG_API_BASE_URL}/subscriptions/${subscriptionId}`;
export const GET_SUBSCRIPTION_BY_EMAIL = (email: string) => `${PLG_API_BASE_URL}/subscriptions/${email}`;
export const PATCH_SUBSCRIPTION = (subscriptionId: string) => `${PLG_API_BASE_URL}/subscriptions/${subscriptionId}`;
export const DELETE_SUBSCRIPTION = (subscriptionId: string) => `${PLG_API_BASE_URL}/subscriptions/${subscriptionId}`;
