import React, { useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { InjectMicrofrontEnd } from 'services/Injectors/MicrofrontEnd';
import { featureflags } from 'libs/featureFlags';
import Sidebar from 'components/Sidebar';
import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import { useAppContext } from 'contexts/AppContext';

interface PluginProps {
  id: string;
  language: string;
  owneridentity: string;
  token: string;
  botname: string;
  channelsactive: string;
  cluster: string;
  plan: string;
  template: string;
  blipdomainurl: string;
  blipwebsockethostname: string;
  blipwebsockethostnametenant: string;
  portaldomain: string;
  authemail: string;
  featureflags: any;
  settings: string;
}

const ClickTrackerIntegration: React.FC = () => {
  const DOMAIN_URL = process.env.REACT_APP_BLIP_DOMAIN_URL || '';
  const WEBSOCKET_HOST_NAME = process.env.REACT_APP_BLIP_WEBSOCKET_HOST_NAME || '';

  const auth = useAuth();
  const { botConfiguration } = useAppContext();

  const botId = botConfiguration.botId;
  const botName = botConfiguration.botName;
  const userData = auth.userData;
  const email = userData?.profile.email;
  const token = userData?.access_token;

  useEffect(() => {
    if (token && email) {
      const pluginProps: PluginProps = {
        id: 'click-tracker',
        blipdomainurl: DOMAIN_URL,
        portaldomain: '',
        blipwebsockethostname: WEBSOCKET_HOST_NAME,
        blipwebsockethostnametenant: WEBSOCKET_HOST_NAME,
        authemail: email,
        language: 'pt',
        owneridentity: `${encodeURIComponent(email)}/${botId}`,
        token: token,
        botname: botName,
        channelsactive: 'blipchat;mailgun;wa',
        template: 'master',
        cluster: 'TAKE',
        plan: 'enterprise',
        settings: `${process.env.REACT_APP_BLIP_MFE_CLICKTRACKER}/latest/settings.json`,
        featureflags: featureflags,
      };
      InjectMicrofrontEnd(
        'clicktracker',
        `${process.env.REACT_APP_BLIP_MFE_CLICKTRACKER}/latest/main.js`,
        '',
        'click-tracker',
        pluginProps,
        '',
        '',
      );
    }
  }, []);

  return (
    <>
      <Sidebar />
      <BdsGrid direction="column" style={{ margin: '4rem 10rem', background: '#fff' }}>
        <div id="clicktracker"></div>
      </BdsGrid>
    </>
  );
};

export default ClickTrackerIntegration;
