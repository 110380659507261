import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import WarningSymbol from '../../../assets/images/warning-symbol.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlipGoCoexLogo from '../../../assets/images/blipgo-coex-logo.svg';

function CnpjRequerimentWarning() {
  const navigate = useNavigate();

  return (
    <BdsGrid justifyContent="center">
      <BdsGrid direction="column" className="main-container onboarding" gap="3">
        <BdsGrid justifyContent="center" alignItems="center">
          <img src={BlipGoCoexLogo} alt="BlipGO CoEX logo" />
        </BdsGrid>
        <BdsGrid padding="t-8">
          <img src={WarningSymbol} />
        </BdsGrid>

        <BdsGrid direction="column" gap="1">
          <BdsTypo bold="bold" variant="fs-32" margin={false}>
            É necessário ter uma empresa com CNPJ ativo para continuar
          </BdsTypo>
          <BdsTypo variant="fs-16">
            Atualmente, apenas empresas podem conectar o WhatsApp Business ao Blip. Durante o processo de conexão, a
            Meta irá verificar a autenticidade da empresa através dos dados cadastrados.
          </BdsTypo>
        </BdsGrid>

        <BdsButton onBdsClick={() => navigate('/onboarding/company-form')}>Informar um CNPJ</BdsButton>
      </BdsGrid>
    </BdsGrid>
  );
}

export default CnpjRequerimentWarning;
