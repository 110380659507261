import { Drawer } from '@mui/material';
import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import './styles.scss';
import MobileDrawerItem from 'components/MobileDrawerItem';
import UserCard from 'components/UserCard';

interface IMobileDrawerProps {
  isDrawerOpen: boolean;
  onClose: () => void;
}

function MobileDrawer({ isDrawerOpen, onClose }: Readonly<IMobileDrawerProps>) {
  return (
    <Drawer anchor="top" open={isDrawerOpen} onClose={onClose} className="host-mobile-drawer">
      <BdsGrid direction="column" padding="3" margin="t-8" gap="3" className="mobile-drawer-content">
        <BdsGrid direction="column" gap="1">
          <MobileDrawerItem icon="home" route="/dashboard" label="Home" onItemClick={onClose} />
          <MobileDrawerItem icon="message-sent" route="/campaign" label="Mensagens ativas" onItemClick={onClose} />
        </BdsGrid>
        <UserCard backgroundColor="grey" />
      </BdsGrid>
    </Drawer>
  );
}

export default MobileDrawer;
