// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyTemplateCard-module__textCentered__yA8Yo {
  text-align: center;
}

.EmptyTemplateCard-module__illustration__T8EMI {
  max-width: 9.375rem;
}`, "",{"version":3,"sources":["webpack://./src/components/EmptyTemplateCard/EmptyTemplateCard.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".textCentered {\n  text-align: center;\n}\n\n.illustration {\n  max-width: 9.375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textCentered": `EmptyTemplateCard-module__textCentered__yA8Yo`,
	"illustration": `EmptyTemplateCard-module__illustration__T8EMI`
};
export default ___CSS_LOADER_EXPORT___;
