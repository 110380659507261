import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import React from 'react';

import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import MobileToolbar from 'components/MobileToolbar';
import MainHeader from 'components/MainHeader';
import EmptyTemplateCard from 'components/EmptyTemplateCard/EmptyTemplateCard';

function EmptyTemplate() {
  const isMobileView = useMobileView();

  return (
    <>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <BdsGrid justifyContent="center">
        <BdsGrid direction="column" className="main-container">
          <MainHeader
            title={'Mensagens ativas'}
            description="Dispare mensagens personalizáveis para vários clientes ao mesmo tempo"
          />
          <BdsGrid direction="column" padding="y-4" gap="3">
            <EmptyTemplateCard />
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </>
  );
}

export default EmptyTemplate;
