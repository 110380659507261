import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import React from 'react';

type IMainContainerProps = {
  children: React.ReactNode;
};

function MainContainer({ children }: IMainContainerProps) {
  return (
    <BdsGrid justifyContent="center">
      <BdsGrid direction="column" className="main-container" gap="3">
        {children}
      </BdsGrid>
    </BdsGrid>
  );
}

export default MainContainer;
