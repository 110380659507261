import img1 from 'assets/images/slides-whatsapp/instrucao-wpp-1.jpg';
import img2 from 'assets/images/slides-whatsapp/instrucao-wpp-2.jpg';
import img3 from 'assets/images/slides-whatsapp/instrucao-wpp-3.jpg';
import img4 from 'assets/images/slides-whatsapp/instrucao-wpp-4.jpg';
import img5 from 'assets/images/slides-whatsapp/instrucao-wpp-5.jpg';
import img6 from 'assets/images/slides-whatsapp/instrucao-wpp-6.jpg';
import img7 from 'assets/images/slides-whatsapp/instrucao-wpp-7.jpg';
import img8 from 'assets/images/slides-whatsapp/instrucao-wpp-8.jpg';
import img9 from 'assets/images/slides-whatsapp/instrucao-wpp-9.jpg';

const slides = [
  {
    img: img1,
    number: 1,
    text: 'Faça login no Facebook e escolha a conta que irá continuar a ativação',
  },
  {
    img: img2,
    number: 2,
    text: 'Concorde com as permissões que serão concedias à Blip e clique para começar',
  },
  {
    img: img3,
    number: 3,
    text: 'Confira que sua empresa está selecionada no campo indicado, preencha os dados e avance.',
  },
  {
    img: img4,
    number: 4,
    text: 'Selecione a opção ”Conecte seu app WhatsApp Business existente” e clique em avançar.',
  },
  {
    img: img5,
    number: 5,
    text: 'Adicione o número que está sendo usado no seu WhatsApp Business e clique em ”Avançar”.',
  },
  {
    img: img6,
    number: 6,
    text: 'Confirme o nome da conta e o fuso horário estão corretos e clique em ”Avançar”.',
  },
  {
    img: img7,
    number: 7,
    text: 'Uma mensagem irá chegar ao WhatsApp Business da sua empresa, siga as etapas e escaneie o QR code.',
  },
  {
    img: img8,
    number: 8,
    text: 'Revise as permissões que serão concedidas a Blip e clique em ”Continuar”.',
  },
  {
    img: img9,
    number: 9,
    text: 'Se chegou até aqui, sua conta foi conectada com sucesso! Basta clicar em "Concluir”.',
  },
];

export default slides;
