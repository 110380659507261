import { BdsCarousel, BdsCarouselItem, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import styles from './WhatsappConnectionCarousel.module.scss';
import CircleNumber from 'components/CircleNumber/CircleNumber';
import slides from 'libs/metaSlidesData';

function WhatsappConnectionCarousel() {
  return (
    <BdsCarousel id="carousel" arrows="outside" grab={true} slide-per-page={2} bullets={false}>
      {slides.map(slide => (
        <BdsCarouselItem key={slide.number}>
          <BdsGrid align-items="center">
            <BdsGrid direction="column" padding="2" gap="1">
              <img className={styles['carousel-image']} src={slide.img} alt={`Slide ${slide.number}`} />
              <BdsGrid gap="2">
                <CircleNumber number={slide.number} />
                <BdsTypo variant="fs-16" bold="bold">
                  {slide.text}
                </BdsTypo>
              </BdsGrid>
            </BdsGrid>
          </BdsGrid>
        </BdsCarouselItem>
      ))}
    </BdsCarousel>
  );
}

export default WhatsappConnectionCarousel;
