import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import BlipgoCoexLogo from '../../assets/images/blipgo-coex-logo.svg';
import './style.scss';

type IMainContainerOnboardingProps = {
  children: React.ReactNode;
  subtitle: string;
  title: string;
};

function MainContainerOnboarding({ children, title, subtitle }: IMainContainerOnboardingProps) {
  return (
    <BdsGrid justifyContent="center" className="onboarding-background">
      <BdsGrid direction="column" className="main-container onboarding" gap="3">
        <BdsGrid justifyContent="center" alignItems="center">
          <img src={BlipgoCoexLogo} alt="BlipGO CoEX logo" />
        </BdsGrid>
        <BdsGrid padding="t-2" direction="column" gap="3">
          <BdsGrid direction="column" gap="1">
            <BdsTypo variant="fs-32" bold="bold" margin={false}>
              {title}
            </BdsTypo>
            <BdsTypo variant="fs-16">{subtitle}</BdsTypo>
          </BdsGrid>
        </BdsGrid>
        {children}
      </BdsGrid>
    </BdsGrid>
  );
}

export default MainContainerOnboarding;
