import { BdsButton, BdsGrid, BdsLoadingPage, BdsSelect, BdsSelectOption, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import React, { useEffect, useState } from 'react';
import styles from './WhatsAppNumberConnect.module.scss';
import MainHeader from 'components/MainHeader';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_META_CONNECTION_COMPLETED_CLOSE } from 'libs/trackingEvents';
import WhatsappConnectionContainer from 'components/WhatsappConnectionContainer';
import { useAtom } from 'jotai';
import { facebookAuthResponse } from 'atoms/AppAtoms';
import useBlipGoApi from 'hooks/useBlipGoApi';
import { PhoneNumberFormated, WhatsappConnectionConfig } from 'types/Whatsapp';
import useToast from 'hooks/useToast';
import { useSetAtom } from 'jotai';
import { errorMessageMeta, isMetaError } from 'atoms/AppAtoms';
import MainContainer from 'components/MainContainer';

function WhatsAppNumberConnect() {
  const isMobileView = useMobileView();
  const [authData] = useAtom(facebookAuthResponse);
  const { userData } = useAuth();
  const { getWabas, activateNumber } = useBlipGoApi();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;
  const navigate = useNavigate();
  const { track } = useTrack();
  const { createToastError } = useToast();
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberFormated[]>([]);
  const [selectedPhone, setSelectedPhone] = useState<PhoneNumberFormated>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setErrorMessageMeta = useSetAtom(errorMessageMeta);
  const setIsMetaError = useSetAtom(isMetaError);

  const handleOnClose = () => {
    track(COEX_META_CONNECTION_COMPLETED_CLOSE);
    navigate('/');
  };

  const handleSubmit = async () => {
    if (!selectedPhone) {
      return;
    }
    const whatsappConnectionConfig: WhatsappConnectionConfig = {
      phoneNumberId: selectedPhone.phoneId,
      whatsAppBusinessAccountId: selectedPhone.wabaId,
      whatsAppBusinessAccountCurrencyCode: 'USD',
      currentUserEmail: (userData?.profile.Email as string) ?? null,
      shouldValidateTicket: false,
      isCoexistenceEnabled: true,
      isChannelActive: true,
      isCloudApiEnabled: true,
    };

    setIsLoading(true);
    try {
      await activateNumber(whatsappConnectionConfig);
      navigate('/whatsapp/connection-finished');
    } catch (error) {
      createToastError('Falha na ativação do número');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchWabaInfo = async () => {
      if (authData?.accessToken) {
        setIsLoading(true);
        try {
          const result = await getWabas(authData.accessToken);
          setPhoneNumbers(result);
        } catch (error) {
          setIsMetaError(true);
          setErrorMessageMeta('Falha ao buscar Whatsapp Business Account');
          navigate('/whatsapp/whatsapp-connect');
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsMetaError(true);
        setErrorMessageMeta('Falha no token');
        navigate('/whatsapp/whatsapp-connect');
      }
    };

    fetchWabaInfo();
  }, [authData?.accessToken]);

  const handleChange = (event: any) => {
    const selectedValue = JSON.parse(event.target.value);
    setSelectedPhone(selectedValue);
  };

  return (
    <>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <MainContainer>
        <MainHeader title={`Boas vindas, ${userName}`} description="" />
        <WhatsappConnectionContainer onClose={handleOnClose}>
          <BdsGrid className="whatsapp-connect-container" xs="11" lg="11" md="11" gap="2" direction="column">
            <BdsGrid direction="row" gap="3" alignItems="center" padding="2">
              <BdsGrid direction="column" gap="2">
                <BdsTypo variant="fs-20" bold="bold">
                  Estamos quase lá! Complete a última etapa
                </BdsTypo>
                <BdsTypo variant="fs-16" bold="bold">
                  Será aberto uma nova janela onde você deve fazer login com o Facebook e seguir as etapas para
                  autorização da conexão com o Whatsapp Business.
                </BdsTypo>
                <BdsTypo variant="fs-14">Caso tenha mais de um número, escolha qual será conectado</BdsTypo>
                {isLoading ? (
                  <BdsLoadingPage />
                ) : (
                  <BdsSelect data-testid="phone-select" className={styles['select-input']} onBdsChange={handleChange}>
                    {phoneNumbers.map(phone => (
                      <BdsSelectOption
                        key={phone.phoneId}
                        value={JSON.stringify({
                          wabaId: phone.wabaId,
                          phoneId: phone.phoneId,
                          phoneNumber: phone.phoneNumber,
                          wabaName: phone.wabaName,
                        })}
                      >
                        {phone.phoneNumber}
                      </BdsSelectOption>
                    ))}
                  </BdsSelect>
                )}
                <BdsButton arrow={true} onBdsClick={handleSubmit}>
                  Finalizar conexão
                </BdsButton>
              </BdsGrid>
            </BdsGrid>
          </BdsGrid>
        </WhatsappConnectionContainer>
      </MainContainer>
    </>
  );
}

export default WhatsAppNumberConnect;
