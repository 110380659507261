import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsGrid,
  BdsIcon,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import FaqWizardInput from 'components/FaqWizard/FaqWizardInput';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { FAQ_NEWFAQ_CLOSED, FAQ_NEWFAQ_CLOSE_CONFIRMED } from 'libs/trackingEvents';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function FaqWizard() {
  const navigate = useNavigate();
  const { track } = useTrack();
  const isMobileView = useMobileView();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [trackPayload, setTrackPayload] = useState({ typeQuestion: 'ADD_PELO_USER', questionName: 'LOJA_FISICA' });

  const closeWizard = () => {
    track(FAQ_NEWFAQ_CLOSE_CONFIRMED, trackPayload);
    navigate('/dashboard');
  };

  const updateTrackPayload = (payload: any) => {
    setTrackPayload(payload);
  };

  return (
    <>
      {!isMobileView && <Sidebar />}
      <BdsGrid justifyContent="center">
        <BdsGrid className="main-container" direction="column">
          <FaqWizardInput setPageHeaderTracking={updateTrackPayload} />
          <BdsAlert open={showAlertModal}>
            <BdsAlertHeader variant="warning" icon="warning">
              Interromper configuração de assuntos?
            </BdsAlertHeader>
            <BdsAlertBody>Seu assistente só ficará ativo após concluir todos os passos.</BdsAlertBody>
            <BdsAlertActions>
              <BdsButton onClick={() => setShowAlertModal(false)} variant="secondary">
                Continuar aqui
              </BdsButton>
              <BdsButton onClick={closeWizard} variant="secondary">
                Voltar à home
              </BdsButton>
            </BdsAlertActions>
          </BdsAlert>
        </BdsGrid>
      </BdsGrid>
    </>
  );
}

export default FaqWizard;
