import React from 'react';
import './style.scss';
import SurveyContainer from 'components/Survey/SurveyContainer';
import { useMobileView } from 'contexts/MobileViewContext';
import Sidebar from 'components/Sidebar';
import MobileToolbar from 'components/MobileToolbar';

function Survey() {
  const isMobileView = useMobileView();

  return (
    <div className="survey-page">
      {!isMobileView ? <Sidebar /> : <MobileToolbar />}
      <SurveyContainer />
    </div>
  );
}

export default Survey;
