import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsGrid,
  BdsInput,
  BdsPaper,
} from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { FAQ_NEWFAQ_CANCEL_CLICKED } from 'libs/trackingEvents';
import React, { useEffect, useRef, useState } from 'react';
import { Faq } from 'types/Faq';
import { PageTitles } from 'types/FaqPage';
import './style.scss';

function FaqInput({
  faq,
  pageTexts,
  saveCallback,
  cancelCallback,
}: {
  faq: Faq;
  pageTexts: PageTitles;
  saveCallback: (faq: Faq) => Promise<void>;
  cancelCallback: () => void;
}) {
  const { track } = useTrack();
  const questionInputRef = useRef<HTMLBdsInputElement>(null);
  const answerInputRef = useRef<HTMLBdsInputElement>(null);
  const keywordsInputRef = useRef<HTMLBdsInputChipsElement>(null);
  const { createToastSuccess, createToastError } = useToast();
  const [isFaqValid, setIsFaqValid] = useState(faq.question?.length > 0);
  const [isLoading, setIsLoading] = useState(false);
  const isMobileView = useMobileView();
  const [showAlertModal, setShowAlertModal] = useState(false);

  const saveFaq = async () => {
    const question = questionInputRef.current?.value;
    const answer = answerInputRef.current?.value;
    const keywords = await keywordsInputRef.current?.get();

    if (question && answer) {
      try {
        setIsLoading(true);
        const randomId = crypto.randomUUID();
        await saveCallback({
          id: faq.id ? faq.id : randomId,
          answer: answer,
          question: question,
          keywords: keywords,
        });
        questionInputRef.current?.clear();
        answerInputRef.current?.clear();
        keywordsInputRef.current?.clear();
        createToastSuccess('Assunto salvo');
      } catch {
        createToastError('Não foi possível salvar a pergunta', 'Tente novamente em alguns instantes');
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const questionInput = questionInputRef.current as HTMLBdsInputElement;
    const answerInput = answerInputRef.current as HTMLBdsInputElement;
    questionInput.value = faq.question ?? '';
    answerInput.value = faq.answer ?? '';
  }, []);

  const validateInputs = async () => {
    const isValid = (await questionInputRef.current?.isValid()) && (await answerInputRef.current?.isValid());
    setIsFaqValid(!!isValid);
  };

  const handleCancel = () => {
    track(FAQ_NEWFAQ_CANCEL_CLICKED);
    setShowAlertModal(true);
  };

  return (
    <BdsGrid justifyContent={isMobileView ? 'center' : 'flex-start'} padding={isMobileView ? 'y-1' : 'y-3'}>
      <BdsPaper className="faq-input-paper" elevation="none">
        <BdsGrid padding="3" gap="2" direction="column">
          <BdsInput
            className="faq-input-area"
            ref={questionInputRef}
            required
            onBdsChange={validateInputs}
            rows={1}
            maxlength={300}
            label="Assunto"
            placeholder="Digite aqui o nome do seu assunto."
            requiredErrorMessage="Este campo é obrigatório"
            helperMessage="Uma pergunta que seus clientes costumam fazer."
          />
          <BdsInput
            className="faq-input-area"
            ref={answerInputRef}
            required
            onBdsChange={validateInputs}
            rows={isMobileView ? 3 : 1}
            maxlength={300}
            label="Resposta"
            placeholder="Digite aqui sua resposta."
            requiredErrorMessage="Este campo é obrigatório"
            helperMessage="Como você costuma responder essa pergunta."
          />
          <BdsGrid gap="1" justifyContent="center" direction={isMobileView ? 'column' : 'row'}>
            <MaterialButton
              disabled={!isFaqValid}
              isMobileView={isMobileView}
              text="Salvar"
              variant="primary"
              cssVariant="w-50"
              onClickCallback={() => saveFaq()}
            ></MaterialButton>
            <MaterialButton
              isMobileView={isMobileView}
              text="Cancelar"
              variant="tertiary-border"
              cssVariant="w-50"
              onClickCallback={() => handleCancel()}
            ></MaterialButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
      <BdsAlert open={showAlertModal}>
        <BdsAlertHeader variant="warning" icon="warning">
          {pageTexts.cancelModalTitle}
        </BdsAlertHeader>
        <BdsAlertBody>{pageTexts.cancelModalSubtitle}</BdsAlertBody>
        <BdsAlertActions>
          <BdsButton onClick={() => setShowAlertModal(false)} variant="secondary">
            Voltar
          </BdsButton>
          <BdsButton bdsLoading={isLoading} onClick={cancelCallback} variant="secondary">
            Sim
          </BdsButton>
        </BdsAlertActions>
      </BdsAlert>
    </BdsGrid>
  );
}

export default FaqInput;
