import { Grid } from '@mui/material';
import { BdsButton, BdsGrid, BdsIcon, BdsInput, BdsModal, BdsTooltip, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { Ref, forwardRef, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import './style.scss';
import { useMobileView } from 'contexts/MobileViewContext';

const TemplatePreviewModal = forwardRef(function TemplatePreviewModal(
  props: { messageContent: string; onConclusion: (variableValues: string[]) => void },
  ref: Ref<HTMLBdsModalElement>,
) {
  const isMobileView = useMobileView();
  const [variablePlaceholders, setVariablePlaceholders] = useState<string[]>([]);
  const [variableExamples, setVariableExamples] = useState<Record<string, string>>({});
  const [editableMessageContent, setEditableMessageContent] = useState<string>(props.messageContent);
  const [isCreationButtonLoading, setIsCreationButtonLoading] = useState(false);
  const isConfirmDisabled = Object.values(variableExamples).some(value => value.trim() === '');

  useEffect(() => {
    const extractVariablePlaceholders = (content: string): string[] => {
      const variablePattern = /\{\{variavel(\d+)\}\}/g;
      return Array.from(content.matchAll(variablePattern)).map(match => match[0]);
    };

    const placeholders = extractVariablePlaceholders(props.messageContent);
    setVariablePlaceholders(placeholders);

    const initialValues = placeholders.reduce(
      (initialValuesMap, placeholder) => {
        initialValuesMap[placeholder] = '';
        return initialValuesMap;
      },
      {} as Record<string, string>,
    );

    setVariableExamples(initialValues);
    setEditableMessageContent(props.messageContent);
  }, [props.messageContent]);

  const handleExampleChange = (placeholder: string, example: string) => {
    setVariableExamples(prevExamples => {
      const updatedExamples = { ...prevExamples, [placeholder]: example };
      updateEditableMessageContent(updatedExamples);
      return updatedExamples;
    });
  };

  const updateEditableMessageContent = (examples: Record<string, string>) => {
    let updatedContent = props.messageContent;
    Object.entries(examples).forEach(([placeholder, example]) => {
      const variableRegex = new RegExp(placeholder, 'g');
      updatedContent = updatedContent.replace(variableRegex, example || placeholder);
    });

    setEditableMessageContent(updatedContent);
  };

  const handleConfirmCreation = () => {
    setIsCreationButtonLoading(true);
    props.onConclusion(Object.values(variableExamples));
  };

  const closeModal = () => {
    const modal = document.getElementById('add-variables-example-modal') as HTMLBdsModalElement;
    if (modal) {
      modal.toggle();
    }
  };

  return (
    <BdsModal id="add-variables-example-modal" ref={ref} size="dynamic" closeButton={false}>
      <BdsGrid padding="1" direction="column" gap={isMobileView ? '3' : '4'}>
        <BdsGrid direction="column" gap="1">
          <BdsTypo variant="fs-24" bold="bold" margin={false}>
            Identifique as variáveis
          </BdsTypo>
          <BdsTypo variant="fs-14">Utilize exemplos para informar como as variáveis serão usadas.</BdsTypo>
        </BdsGrid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div className="template-preview-message-container">
              <BdsTypo>{parse(editableMessageContent)}</BdsTypo>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <BdsGrid direction="column" gap="2">
              <BdsGrid gap="1" alignItems="center">
                <BdsTypo variant="fs-14" bold="bold">
                  Exemplos de preenchimento
                </BdsTypo>
                <BdsTooltip
                  id="tooltip"
                  position="bottom-right"
                  className="variables-example-tooltip"
                  tooltip-text="Informe um exemplo real de como a variável será preenchida. Ex.: caso seja uma variável de nome, pode ser preenchida como ”José”."
                >
                  <BdsIcon name="info"></BdsIcon>
                </BdsTooltip>
              </BdsGrid>

              <BdsGrid direction="column" gap="2" className="variables-example-input-container">
                {variablePlaceholders.map((placeholder, index) => (
                  <BdsInput
                    data-testid={`variable-example-${index}`}
                    key={index}
                    label={placeholder}
                    value={variableExamples[placeholder]}
                    placeholder="Insira um exemplo"
                    onBdsChange={(event: any) => handleExampleChange(placeholder, event.target.value)}
                  />
                ))}
              </BdsGrid>
            </BdsGrid>
          </Grid>
        </Grid>
        <BdsGrid gap="1" justifyContent="flex-end">
          <BdsButton variant="text" color="content" onBdsClick={closeModal}>
            Cancelar
          </BdsButton>
          <BdsButton
            bdsLoading={isCreationButtonLoading}
            onBdsClick={handleConfirmCreation}
            disabled={isConfirmDisabled}
          >
            Confirmar
          </BdsButton>
        </BdsGrid>
      </BdsGrid>
    </BdsModal>
  );
});

export default TemplatePreviewModal;
