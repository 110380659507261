// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instagram-page-item {
  margin-top: 1.5rem;
  border: 0.0625rem solid var(--color-border-2, rgba(0, 0, 0, 0.16));
  border-radius: 1rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramSteps/InstagramActivation/style.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,kEAAA;EACA,mBAAA;EACA,gCAAA;AADF","sourcesContent":["@use '/src/styles/colors';\n\n.instagram-page-item {\n  margin-top: 1.5rem;\n  border: 0.0625rem solid colors.$color-border-2;\n  border-radius: 1rem;\n  padding: 1rem 1.5rem 1rem 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
