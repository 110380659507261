import { BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

function FaqHeader({
  pageTexts,
  showInputFaq,
  cancelInputCallback,
}: {
  pageTexts: { headerTitle: string; pageTitle: string; text: string };
  showInputFaq: boolean;
  cancelInputCallback: () => void;
}) {
  const navigate = useNavigate();
  const backToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <BdsGrid className="headerfaq" padding="y-2" alignItems="center">
      <BdsGrid xxs="10" padding="l-3" direction="row" gap="2">
        {!showInputFaq && (
          <BdsGrid xxs="1" padding="r-3" justifyContent="flex-start">
            <BdsIcon className="arrow-left" onClick={backToDashboard} name="arrow-left" type="icon" size="large" />
          </BdsGrid>
        )}

        <BdsGrid direction="column">
          <BdsTypo variant="fs-16" bold="bold" lineHeight="plus">
            {pageTexts.headerTitle}
          </BdsTypo>
          <BdsTypo variant="fs-12" lineHeight="plus">
            {pageTexts.text}
          </BdsTypo>
        </BdsGrid>
      </BdsGrid>
      {showInputFaq && (
        <BdsGrid xxs="1" padding="l-6" justifyContent="flex-end">
          <BdsIcon className="close" onClick={() => cancelInputCallback()} name="close" type="icon" size="large" />
        </BdsGrid>
      )}
    </BdsGrid>
  );
}

export default FaqHeader;
