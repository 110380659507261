// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhatsAppNumberConnect-module__select-input__EaGJ2 {
  width: 40%;
  max-width: 18.75rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/WhatsApp/WhatsAppNumberConnect/WhatsAppNumberConnect.module.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,mBAAA;AADF","sourcesContent":["@use 'src/styles/colors';\n\n.select-input {\n  width: 40%;\n  max-width: 18.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-input": `WhatsAppNumberConnect-module__select-input__EaGJ2`
};
export default ___CSS_LOADER_EXPORT___;
