import { BdsButton, BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { useState } from 'react';
import './styles.scss';
import MainContainerOnboarding from 'components/MainContainerOnboarding';
import { useMobileView } from 'contexts/MobileViewContext';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_SETUP_ACCEPT_TERMS } from 'libs/trackingEvents';

function TermsAgreement() {
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [communicationAgreement, setCommunicationAgreement] = useState(false);
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const { track } = useTrack();

  const isEventALinkClick = (event: React.MouseEvent) => {
    return event.target instanceof HTMLAnchorElement;
  };

  const handleTermsChange = async (event: React.MouseEvent) => {
    if (isEventALinkClick(event)) {
      return;
    }
    setTermsAgreement(!termsAgreement);
  };

  const handleNextStep = () => {
    track(COEX_SETUP_ACCEPT_TERMS);
    navigate('/onboarding/installing');
  };

  return (
    <MainContainerOnboarding
      subtitle="Para finalizar, aceite os termos de adesão do produto."
      title="Termos e condições"
    >
      <BdsGrid justifyContent="space-between" gap={isMobileView ? '2' : '4'} className="plan-container">
        <BdsGrid direction="column" justifyContent="center">
          <BdsTypo variant="fs-16" bold="bold">
            Plano Coex Starter
          </BdsTypo>
          <BdsTypo variant="fs-14">
            Análise de conversas, assistente virtual, disparo de mensagens em massa e acompanhamento de conversão em
            anúncios.
          </BdsTypo>
        </BdsGrid>
        <BdsGrid alignItems="center">
          <BdsTypo bold="bold" className="plan-price">
            Grátis
          </BdsTypo>
        </BdsGrid>
      </BdsGrid>
      <BdsGrid
        gap="2"
        alignItems="center"
        className={`term-checkbox-container ${termsAgreement && 'selected-checkbox'}`}
        onClick={handleTermsChange}
      >
        <Checkbox
          checked={termsAgreement}
          onChange={ev => setTermsAgreement(ev.target.checked)}
          className="custom-checkbox"
        />
        <BdsTypo>
          Eu concordo com os{' '}
          <a className="terms-link" target="_blank" href="">
            Termos e Condições de Uso
          </a>{' '}
          do Blip Coex.
        </BdsTypo>
      </BdsGrid>

      <BdsGrid
        gap="2"
        className={`term-checkbox-container ${communicationAgreement && 'selected-checkbox'}`}
        onClick={() => setCommunicationAgreement(!communicationAgreement)}
      >
        <Checkbox
          checked={communicationAgreement}
          onChange={ev => setCommunicationAgreement(ev.target.checked)}
          className="custom-checkbox"
        />
        <BdsTypo>
          Aceito receber comunicações do Blip Coex no WhatsApp que podem incluir orientações e avisos sobre o produto.
        </BdsTypo>
      </BdsGrid>
      <BdsGrid justifyContent="space-between">
        <BdsButton variant="text" color="content" onBdsClick={() => navigate('/onboarding/company-form')}>
          Voltar
        </BdsButton>
        <BdsButton disabled={!termsAgreement || !communicationAgreement} onBdsClick={handleNextStep}>
          Acessar Blip Coex
        </BdsButton>
      </BdsGrid>
    </MainContainerOnboarding>
  );
}

export default TermsAgreement;
