// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connected-account-container {
  border-radius: 1rem;
  border: 0.0625rem solid var(--color-border-2, rgba(0, 0, 0, 0.16));
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  align-self: flex-start;
}

.connected-account-container-mobile {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/FacebookConectedAccount/style.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,kEAAA;EACA,4BAAA;EACA,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,WAAA;AADF","sourcesContent":["@use '/src/styles/colors';\n\n.connected-account-container {\n  border-radius: 1rem;\n  border: 0.0625rem solid colors.$color-border-2;\n  padding: 1rem 2rem 1rem 2rem;\n  display: flex;\n  align-self: flex-start;\n}\n\n.connected-account-container-mobile {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
