import { BdsButton, BdsGrid, BdsIcon, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import Sidebar from 'components/Sidebar';
import WhatsappConnectionContainer from 'components/WhatsappConnectionContainer';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import styles from './WhatsAppBusinessCheck.module.scss';
import MetaBusinessOverview from 'assets/images/meta-business-overview.svg';
import MainHeader from 'components/MainHeader';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_CANCEL_CONNECTION } from 'libs/trackingEvents';
import MainContainer from 'components/MainContainer';

function WhatsAppBusinessCheck() {
  const isMobileView = useMobileView();
  const { userData } = useAuth();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;
  const navigate = useNavigate();
  const { track } = useTrack();

  const handleNextClick = () => {
    navigate('/whatsapp/whatsapp-connect');
  };

  const handleOnClose = () => {
    track(COEX_CANCEL_CONNECTION);
    navigate('/');
  };

  return (
    <>
      <Sidebar />
      <MainContainer>
        <MainHeader
          title={`Boas vindas, ${userName}`}
          description="Gerencie o seu atendimento e campanhas com o Blip Go Coex!"
        />
        <WhatsappConnectionContainer onClose={handleOnClose}>
          <BdsGrid className={styles['business-check-container']} gap="4">
            <BdsGrid direction="column" gap="4">
              <BdsGrid direction="column">
                <BdsTypo variant="fs-20" bold="bold">
                  Crie ou acesse sua conta no Gerenciador de Negócios da meta
                </BdsTypo>
                <BdsTypo>
                  É através do Gerenciador que é autorizada a conexão do WhatsApp, se sua empresa já tem uma página no
                  Facebook, é provável que já tenha uma conta.
                </BdsTypo>
              </BdsGrid>
              <BdsGrid gap="1">
                <BdsIcon name="info" className={styles['info-text']} />
                <BdsTypo variant="fs-14" className={styles['info-text']}>
                  <b>Como fazer? </b>
                  <a
                    href="https://business.facebook.com/latest/settings/business_info?business_id"
                    target="_blank"
                    className={styles['info-text']}
                  >
                    Acesse esse link
                  </a>{' '}
                  e faça login com a conta do Facebook que gerencia a página da sua empresa. Se, após o login,
                  visualizar uma tela semelhante à imagem ao lado, está tudo pronto para continuar.
                </BdsTypo>
              </BdsGrid>
              <BdsButton arrow={true} onBdsClick={handleNextClick} data-testid="next-button">
                Já acessei a conta do Gerenciador
              </BdsButton>
            </BdsGrid>
            {!isMobileView && (
              <img
                src={MetaBusinessOverview}
                alt="meta business overview"
                className={styles['meta-business-illustration']}
              />
            )}
          </BdsGrid>
        </WhatsappConnectionContainer>
      </MainContainer>
    </>
  );
}

export default WhatsAppBusinessCheck;
