import { IPIFY_API_HOST } from 'libs/ipifyApiRoutes';
import { ILogArgs } from 'packs-template-baseweb';
import { RequestErrorBoundary } from 'utils/request';

const LOGS_ARGS: ILogArgs = {
  className: 'useIpifyApi',
};

const useIpifyApi = () => {
  const getPublicIP = async () => {
    LOGS_ARGS.methodName = 'getPublicIP';
    try {
      const response = await fetch(IPIFY_API_HOST);
      return await RequestErrorBoundary(response, LOGS_ARGS);
    } catch (ex) {
      throw new Error(JSON.stringify(ex));
    }
  };
  return {
    getPublicIP,
  };
};

export default useIpifyApi;
