const API_HOST = process.env.REACT_APP_PACKS_API_HOST;
const API_VERSION = 'api/v1';
export const INSTALL_PACK = `${API_HOST}/${API_VERSION}/installations`;
export const TENANTS = `${API_HOST}/${API_VERSION}/tenants`;
export const INSTALLATION_STATUS = `${API_HOST}/${API_VERSION}/statusprocess`;
export const GET_BOTID = `${INSTALL_PACK}/router-shortname`;
export const GET_CHANNEL_STATUS = (botId: string) => `${INSTALL_PACK}/${botId}/channels`;
export const RETRY_INSTALLATION = `${INSTALL_PACK}/update`;
export const GET_LAST_INSTALLATION = `${INSTALL_PACK}/last`;
export const GET_INSTAGRAM_BUSINESS_ACCOUNTS = `${API_HOST}/${API_VERSION}/instagram/business-account`;
export const ACTIVATE_INSTAGRAM = `${API_HOST}/${API_VERSION}/instagram/activate-instagram`;
export const ENABLE_STORY_REPLY = `${API_HOST}/${API_VERSION}/instagram/enable-story-reply`;
export const GET_FACEBOOK_APP_INFORMATION = `${API_HOST}/${API_VERSION}/meta/facebook-app-information`;
export const HUBSPOT_UPDATE_CONTACT = `${API_HOST}/${API_VERSION}/hubspot/contact`;
export const ACTIVATE_NUMBER_WHATSAPP = `${API_HOST}/${API_VERSION}/whatsapp/activate-number`;
