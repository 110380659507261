import { BdsChipTag, BdsGrid, BdsLoadingPage, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import FacebookCatalogModal from 'components/FacebookCatalogModal';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { CONNECT_CATALOG, DISCONNECT_CATALOG, UPDATE_CATALOG } from 'libs/trackingEvents';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserData } from 'types/Facebook';
import './style.scss';

function CatalogPaperCard() {
  const { facebookUserData, setFacebookUserData } = useAppContext();
  const { disableCatalog } = usePlgApi();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { createToastSuccess, createToastError } = useToast();
  const { track } = useTrack();
  const navigate = useNavigate();
  const facebookCatalogModalRef = useRef<HTMLBdsModalElement | null>(null);
  const isMobileView = useMobileView();

  const connectCatalog = () => {
    track(CONNECT_CATALOG);
    navigate('/catalog');
  };

  const updateCatalog = () => {
    track(UPDATE_CATALOG);
    navigate('/catalog');
  };

  const disconnectCatalog = async () => {
    try {
      setIsButtonLoading(true);
      await disableCatalog();
      setFacebookUserData({} as UserData);
      track(DISCONNECT_CATALOG, { status: 'Success' });
      createToastSuccess('Catálogo desconectado com sucesso');
    } catch (error) {
      const err = error as Error;
      track(DISCONNECT_CATALOG, { status: 'Error', error: err.message });
      createToastError('Não foi possível desconectar o catálogo', 'Tente novamente em alguns instantes');
    } finally {
      setIsButtonLoading(false);
    }
  };

  if (facebookUserData.isCatalogSubmitted) {
    return (
      <>
        {isButtonLoading && <BdsLoadingPage />}
        <BdsPaper className="paper-pattern" elevation="none">
          <BdsGrid direction="column" gap="2" padding="3">
            <BdsTypo variant="fs-24" tag="h3" bold="bold" margin={false}>
              {facebookUserData.catalog.name}
            </BdsTypo>
            <BdsChipTag color="success" icon="checkball">
              Conectado
            </BdsChipTag>
            <div>
              <BdsTypo variant="fs-14" tag="p" className="description" margin={false}>
                <strong>Usuário: </strong>
                {facebookUserData.user.name}
              </BdsTypo>
              <BdsTypo variant="fs-14" tag="p" className="description" margin={false}>
                <strong>Organização: </strong>
                {facebookUserData.business.name}
              </BdsTypo>
            </div>
            <BdsGrid gap="2" flexWrap="wrap">
              <MaterialButton
                startIconBdsName="refresh"
                onClickCallback={updateCatalog}
                variant="tertiary"
                text="Atualizar"
                isMobileView={isMobileView}
              ></MaterialButton>
              <MaterialButton
                startIconBdsName="plugin"
                onClickCallback={disconnectCatalog}
                variant="tertiary"
                text="Desconectar"
                isMobileView={isMobileView}
              />
            </BdsGrid>
          </BdsGrid>
        </BdsPaper>
        <FacebookCatalogModal
          modalToggleCallback={() => {
            return facebookCatalogModalRef.current?.toggle();
          }}
          ref={facebookCatalogModalRef}
        />
      </>
    );
  }

  return (
    <BdsPaper className="paper-pattern" elevation="none">
      <BdsGrid direction="column" gap="2" padding="3">
        <BdsTypo variant="fs-24" tag="h3" bold="bold" margin={false}>
          Catálogo
        </BdsTypo>
        <BdsChipTag color="outline" icon="info">
          sem catálogo conectado
        </BdsChipTag>
        <div>
          <BdsTypo variant="fs-14" tag="p" id="description" margin={false}>
            Melhore as respostas do seu assistente com seu catálogo de produtos.
          </BdsTypo>
        </div>
        <MaterialButton onClickCallback={connectCatalog} text="Conectar catálogo" variant="secondary" isMobileView />
      </BdsGrid>
      <FacebookCatalogModal
        modalToggleCallback={() => {
          return facebookCatalogModalRef.current?.toggle();
        }}
        ref={facebookCatalogModalRef}
      />
    </BdsPaper>
  );
}

export default CatalogPaperCard;
