import {
  BdsAlert,
  BdsAlertActions,
  BdsAlertBody,
  BdsAlertHeader,
  BdsButton,
  BdsGrid,
  BdsPaper,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { DELETE_CONFIRM_QUESTION_FAQ } from 'libs/trackingEvents';
import React, { useState } from 'react';
import { Faq } from 'types/Faq';
import { isEmpty } from 'utils/assertions';
import './style.scss';

function FaqItem({
  areChangesAllowed,
  faq,
  editCallback,
  deleteCallback,
}: {
  areChangesAllowed: boolean;
  faq: Faq;
  editCallback: (faq: Faq) => void;
  deleteCallback: (faq: Faq) => Promise<void>;
}) {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { createToastSuccess, createToastError } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { track } = useTrack();
  const isMobileView = useMobileView();

  const deleteFaq = async () => {
    try {
      setIsLoading(true);
      await deleteCallback(faq);
      track(DELETE_CONFIRM_QUESTION_FAQ, {
        questionId: faq.id,
        status: 'Success',
      });
      setIsLoading(false);
      setShowAlertModal(false);
      createToastSuccess('Pergunta apagada com sucesso!');
    } catch (error) {
      const err = error as Error;
      track(DELETE_CONFIRM_QUESTION_FAQ, {
        questionId: faq.id,
        status: 'Error',
        error: err.message,
      });
      createToastError('Não foi possível apagar a pergunta.', err.message);
    }
  };

  return (
    <>
      <BdsPaper className="faq-item" elevation="none">
        <BdsGrid margin="3" alignItems="center" direction={isMobileView ? 'column' : 'row'}>
          <BdsGrid lg="4" md="4" direction="column">
            <BdsTypo variant="fs-14" bold="bold">
              {faq.question}
            </BdsTypo>
            <BdsTypo className="color-disable" variant="fs-12">
              {!isEmpty(faq.keywords) && `Palavras-chave: ${faq.keywords?.toLocaleString()}`}
            </BdsTypo>
          </BdsGrid>
          <BdsGrid lg="4" md="4">
            <BdsTypo className="answer-text" variant="fs-14" lineHeight="double">
              {faq.answer}
            </BdsTypo>
          </BdsGrid>
          <BdsGrid lg="4" md="4" gap="8" justifyContent="space-between">
            <BdsButton
              onClick={() => {
                if (areChangesAllowed) {
                  setShowAlertModal(true);
                }
              }}
              disabled={!areChangesAllowed}
              icon="trash"
              variant="secondary"
            >
              Excluir
            </BdsButton>
            <BdsButton
              variant="secondary"
              onClick={() => {
                if (areChangesAllowed) {
                  editCallback(faq);
                }
              }}
              disabled={!areChangesAllowed}
              arrow
            >
              Editar
            </BdsButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
      <BdsAlert open={showAlertModal}>
        <BdsAlertHeader variant="warning" icon="warning">
          Deseja excluir o assunto?
        </BdsAlertHeader>
        <BdsAlertBody>Ele não poderá ser recuperada após ser excluída.</BdsAlertBody>
        <BdsAlertActions>
          <BdsButton onClick={() => setShowAlertModal(false)} variant="secondary">
            Cancelar
          </BdsButton>
          <BdsButton bdsLoading={isLoading} onClick={deleteFaq} variant="secondary">
            Excluir
          </BdsButton>
        </BdsAlertActions>
      </BdsAlert>
    </>
  );
}

export default FaqItem;
