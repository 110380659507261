import { BdsGrid, BdsProgressBar, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import Hourglass from '../../../assets/images/hourglass.svg';
import BlipGoCoexLogo from '../../../assets/images/blipgo-coex-logo.svg';
import React, { useEffect, useState } from 'react';
import './style.scss';
import useTrack from 'hooks/useTrack';
import { COEX_INSTALLATION_STATUS } from 'libs/trackingEvents';
import usePacksApi from 'hooks/usePacksApi';
import usePlgApi from 'hooks/usePlgApi';
import useBlipGoApi from 'hooks/useBlipGoApi';
import { useNavigate } from 'react-router-dom';
import useToast from 'hooks/useToast';
import { useAppContext } from 'contexts/AppContext';
import { useAtom } from 'jotai';
import { signerInfo } from 'atoms/AppAtoms';
import useIpifyApi from 'hooks/useIpifyApi';
import { useAuth } from 'oidc-react';

function PackInstallProgress() {
  const navigate = useNavigate();
  const { createToastError } = useToast();
  const { track } = useTrack();
  const { installPack, poll } = usePacksApi();
  const { initialBotConfiguration, createBotConfigurations, setSubscription } = usePlgApi();
  const { setBotConfiguration } = useAppContext();
  const { delegateBot } = useBlipGoApi();
  const [progressPercent, setProgressPercent] = useState(0);
  const [signerData] = useAtom(signerInfo);
  const { getPublicIP } = useIpifyApi();
  const { userData } = useAuth();
  let approximateLocation = 'Não informado.';
  if (!signerData?.cnpj) {
    navigate('/');
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success => {
      const { latitude, longitude } = success.coords;
      approximateLocation = `${latitude},${longitude}`;
    });
    createUserBotData();
  }, []);

  useEffect(() => {
    if (progressPercent === 99) return;
    const timer = setInterval(() => {
      setProgressPercent(prevPercent => prevPercent + 1);
    }, 350);
    return () => clearInterval(timer);
  }, [progressPercent]);

  const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Edg') > -1) {
      return 'Microsoft Edge';
    } else if (userAgent.indexOf('Chrome') > -1) {
      return 'Chrome';
    } else if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
    } else if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
    } else if (userAgent.indexOf('Opera') > -1) {
      return 'Opera';
    } else if (userAgent.indexOf('Trident') > -1 || userAgent.indexOf('MSIE') > -1) {
      return 'Internet Explorer';
    }

    return 'Unknown';
  };

  const createUserBotData = async () => {
    try {
      // TODO remove this comment when trackings calls got fixed
      // track(START_INSTALLATION);
      const pack = await installPack();
      const botConfigurations = initialBotConfiguration(pack.routerShortName, signerData?.whatsapp);
      setBotConfiguration(botConfigurations);
      await createBotConfigurations(botConfigurations);
      await poll(pack.installationId);
      const { ip } = await getPublicIP();
      await setSubscription({
        ...signerData,
        name: (userData?.profile?.FullName as string) || (userData?.profile?.FirstName as string),
        email: userData?.profile.email,
        device: `IP:${ip} | Navegador: ${detectBrowser()}`,
        approximateLocation,
        subscriptionPlan: 'Blip Coex Starter',
        dealId: '',
        companyId: '',
        botId: pack.routerShortName,
      });
      await delegateBot(botConfigurations.botId);
      setProgressPercent(100);
      track(COEX_INSTALLATION_STATUS, { status: 'success' });
      return navigate('/dashboard');
    } catch (error) {
      createToastError('Erro ao criar o assistente', 'Por favor, tente novamente.');
      track(COEX_INSTALLATION_STATUS, { status: 'Error', error: JSON.stringify(error) });
      navigate('/onboarding/fail');
    }
  };

  return (
    <BdsGrid justifyContent="center">
      <BdsGrid direction="column" className="main-container onboarding">
        <BdsGrid justifyContent="center" alignItems="center">
          <img src={BlipGoCoexLogo} alt="BlipGO CoEX logo" />
        </BdsGrid>
        <BdsGrid direction="column" justifyContent="center" alignItems="center" gap="4" padding="t-8">
          <img src={Hourglass} />
          <BdsGrid direction="column" justifyContent="center" alignItems="center" gap="3">
            <BdsTypo variant="fs-32" bold="bold" margin={false} className="install-info-title">
              Quase lá! Estamos finalizando a configuração da sua conta Blip Coex
            </BdsTypo>
            <BdsTypo variant="fs-16">Este processo pode levar alguns minutos.</BdsTypo>
          </BdsGrid>

          <BdsGrid direction="column" gap="1" className="install-progress-bar">
            <BdsProgressBar size="default" percent={progressPercent} className="install-progress-bar"></BdsProgressBar>
            <BdsGrid>
              <BdsTypo bold="bold" variant="fs-12">
                {progressPercent}% concluído, configurando sua experiência...
              </BdsTypo>
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </BdsGrid>
  );
}

export default PackInstallProgress;
