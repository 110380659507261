import { BdsGrid, BdsIcon, BdsPaper, BdsTab, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import './style.scss';
import MaterialButton from 'components/UI/MaterialButton';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { SURVEY_AUTOMATIC_MESSAGES } from 'libs/trackingEvents';
import { useNavigate } from 'react-router-dom';

function PrivateReplyTest() {
  const isMobileView = useMobileView();
  const navigate = useNavigate();
  const { track } = useTrack();
  const { createToastSuccess, createToastSystem } = useToast();

  const handleUserResponse = (response: string) => {
    if (response === 'yes') {
      track(SURVEY_AUTOMATIC_MESSAGES, { ClickedOption: 'Quero Testar Primeiro' });
      createToastSuccess(
        'Registramos seu interesse!',
        'Assim que a funcionalidade estiver pronta, você receberá um convite para testá-la antes de tudo mundo!',
      );
    } else {
      track(SURVEY_AUTOMATIC_MESSAGES, { ClickedOption: 'Não, prefiro esperar' });
      createToastSystem('Agradecemos o feedback!');
    }
    navigate('/dashboard');
    localStorage.setItem('privateReplySurvey', 'true');
  };

  return (
    <BdsGrid direction="column" gap="4">
      <BdsGrid direction={isMobileView ? 'column' : 'row'} gap="4">
        {isMobileView ? (
          <BdsGrid gap="2" direction="column">
            <BdsGrid class="text-description">
              <BdsTypo variant="fs-20" bold="bold">
                Mensagens automáticas para comentários.
              </BdsTypo>
            </BdsGrid>
            <BdsPaper elevation="none" class="instagram-paper">
              <BdsGrid direction="column" gap="2">
                <BdsGrid direction="row" justifyContent="space-between">
                  <BdsGrid gap="3">
                    <BdsIcon name="heart" theme="outline" />
                    <BdsIcon name="message-ballon" theme="outline" />
                    <BdsIcon name="send" theme="outline" />
                  </BdsGrid>
                  <BdsGrid>
                    <BdsIcon name="save-flag" theme="outline" />
                  </BdsGrid>
                </BdsGrid>
                <BdsGrid direction="row">
                  <BdsGrid gap="1">
                    <BdsIcon name="heart" theme="solid" size="x-small" />
                    <BdsTypo variant="fs-12">362 likes</BdsTypo>
                  </BdsGrid>
                </BdsGrid>
                <BdsGrid class="text-legend">
                  <BdsTypo variant="fs-12" bold="bold">
                    perfildoinstagram
                  </BdsTypo>
                  <BdsTypo variant="fs-12">comente</BdsTypo>
                  <BdsTypo class="custom-color" variant="fs-12">
                    #QUERO
                  </BdsTypo>
                  <BdsTypo variant="fs-12">para receber o link da promoção!</BdsTypo>
                  <BdsTypo class="custom-color" variant="fs-12">
                    #hashtags
                  </BdsTypo>
                </BdsGrid>
              </BdsGrid>
            </BdsPaper>
            <BdsGrid class="text-description">
              <BdsTypo variant="fs-16">
                Escolha uma palavra para seus usuários comentarem e receberem uma mensagem automática do seu assistente.
              </BdsTypo>
            </BdsGrid>
          </BdsGrid>
        ) : (
          <>
            <BdsGrid class="text-description">
              <BdsTypo variant="fs-20" bold="bold">
                Mensagens automáticas para comentários.
              </BdsTypo>
              <BdsTypo variant="fs-16">
                Escolha uma palavra para seus usuários comentarem e receberem uma mensagem automática do seu assistente.
              </BdsTypo>
            </BdsGrid>
            <BdsPaper elevation="none" class="instagram-paper">
              <BdsGrid direction="column" gap="2">
                <BdsGrid direction="row" justifyContent="space-between">
                  <BdsGrid gap="3">
                    <BdsIcon name="heart" theme="outline" />
                    <BdsIcon name="message-ballon" theme="outline" />
                    <BdsIcon name="send" theme="outline" />
                  </BdsGrid>
                  <BdsGrid>
                    <BdsIcon name="save-flag" theme="outline" />
                  </BdsGrid>
                </BdsGrid>
                <BdsGrid direction="row">
                  <BdsGrid gap="1">
                    <BdsIcon name="heart" theme="solid" size="x-small" />
                    <BdsTypo variant="fs-12">362 likes</BdsTypo>
                  </BdsGrid>
                </BdsGrid>
                <BdsGrid class="text-legend">
                  <BdsTypo variant="fs-12" bold="bold">
                    perfildoinstagram
                  </BdsTypo>
                  <BdsTypo variant="fs-12">comente</BdsTypo>
                  <BdsTypo class="custom-color" variant="fs-12">
                    #QUERO
                  </BdsTypo>
                  <BdsTypo variant="fs-12">para receber o link da promoção!</BdsTypo>
                  <BdsTypo class="custom-color" variant="fs-12">
                    #hashtags
                  </BdsTypo>
                </BdsGrid>
              </BdsGrid>
            </BdsPaper>
          </>
        )}
      </BdsGrid>
      <BdsPaper elevation="none" class="paper-interest">
        <BdsGrid gap="4" direction="column">
          <BdsTypo variant="fs-14">
            Confirme seu <b>interesse</b> e faça parte dos <b>primeiros usuários a testar</b> essa funcionalidade!
          </BdsTypo>
          <BdsGrid gap="3" direction={isMobileView ? 'column' : 'row'}>
            <MaterialButton
              onClickCallback={() => handleUserResponse('yes')}
              text="Quero testar primeiro"
              variant="primary"
              isMobileView={isMobileView}
            ></MaterialButton>
            <MaterialButton
              onClickCallback={() => handleUserResponse('not')}
              text="Não, prefiro esperar"
              variant="tertiary"
              isMobileView={isMobileView}
            ></MaterialButton>
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
    </BdsGrid>
  );
}
export default PrivateReplyTest;
