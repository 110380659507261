import { BdsGrid, BdsIcon, BdsIllustration, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { CONNECT_INSTAGRAM_PAGE_ERROR, CONNECT_INSTAGRAM_PAGE_ERROR_BACK } from 'libs/trackingEvents';
import React, { useEffect } from 'react';
import './style.scss';

type InstagramValidAccountNotFoundProps = {
  backToStartClickCallback: () => void;
};

function InstagramValidAccountNotFound({ backToStartClickCallback }: InstagramValidAccountNotFoundProps) {
  const isMobileView = useMobileView();
  const { track } = useTrack();

  useEffect(() => {
    track(CONNECT_INSTAGRAM_PAGE_ERROR, {
      error: 'No valid instagram account found',
    });
  }, []);

  const onBackToStartClick = () => {
    track(CONNECT_INSTAGRAM_PAGE_ERROR_BACK);
    backToStartClickCallback();
  };

  return (
    <BdsGrid justifyContent="space-between">
      <BdsGrid direction="column">
        <BdsGrid direction="row" gap="2" alignItems="flex-start" xg="12" lg="12" md="12" xxs="12" margin="t-1">
          {isMobileView && (
            <BdsGrid direction="column">
              <BdsIllustration className="ilustration" type="spots" name="letter-cancel-error" />
            </BdsGrid>
          )}
          <BdsTypo tag="span" lineHeight="plus" variant={!isMobileView ? 'fs-32' : 'fs-20'} bold="bold">
            Não encontramos uma conta válida.
          </BdsTypo>
        </BdsGrid>
        <BdsGrid direction="column" gap="1" margin={!isMobileView ? 't-4' : 't-3'}>
          <BdsTypo tag="p" lineHeight="plus" variant="fs-16">
            É possível que algum dos passos anteriores tenha sido feito de forma incorreta.
          </BdsTypo>
        </BdsGrid>
        <BdsGrid className="tip" margin="t-2" gap="2" direction="column">
          <BdsGrid direction="row" gap="1" alignItems="flex-start">
            <BdsIcon name="blip-ideas" type="icon" theme="outline" />
            <BdsTypo tag="p" variant="fs-16" lineHeight="plus" bold="bold">
              Como solucionar o problema:
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column" padding="l-1">
            <BdsTypo tag="p" variant="fs-16" lineHeight="double">
              <b>Volte ao início</b>, garantindo que:
            </BdsTypo>
            <BdsGrid direction="column" padding="l-1">
              <ul className="bullet-list">
                <li>
                  <BdsTypo tag="span" variant="fs-16" lineHeight="plus">
                    Você tem uma <b>página comercial</b> no Facebook.
                  </BdsTypo>
                </li>
                <li>
                  <BdsTypo tag="span" variant="fs-16" lineHeight="plus">
                    Sua conta do Instagram é <b>profissional</b>.
                  </BdsTypo>
                </li>
                <li>
                  <BdsTypo tag="span" variant="fs-16" lineHeight="plus">
                    Sua conta do Instagram está <b>vinculada</b> à sua página do Facebook.
                  </BdsTypo>
                </li>
                <li>
                  <BdsTypo tag="span" variant="fs-16" lineHeight="plus">
                    <b>O acesso às mensagens</b> está permitido.
                  </BdsTypo>
                </li>
              </ul>
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>
        <BdsGrid margin="t-4" gap={isMobileView ? '2' : undefined} direction={!isMobileView ? 'row' : 'column-reverse'}>
          <BdsGrid xg="12" lg="12" md="12" xxs="12" justifyContent="flex-end">
            <MaterialButton
              onClickCallback={() => onBackToStartClick()}
              text="Voltar ao início"
              variant="primary"
              startIconBdsName="arrow-left"
              isMobileView={isMobileView}
            ></MaterialButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
      {!isMobileView && (
        <BdsGrid direction="column" className="space-10">
          <BdsIllustration type="spots" name="letter-cancel-error" />
        </BdsGrid>
      )}
    </BdsGrid>
  );
}

export default InstagramValidAccountNotFound;
