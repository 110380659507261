import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import MainHeader from 'components/MainHeader';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import { COEX_META_CONNECTION_COMPLETED_CLOSE } from 'libs/trackingEvents';
import WhatsapppConectionCard from 'components/WhatsapppConectionCard';
import MainContainer from 'components/MainContainer';

function WhatsappConnectionFinished() {
  const isMobileView = useMobileView();
  const { userData } = useAuth();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;
  const navigate = useNavigate();
  const { track } = useTrack();

  const handleOnClose = () => {
    track(COEX_META_CONNECTION_COMPLETED_CLOSE);
    navigate('/');
  };

  return (
    <>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <MainContainer>
        <MainHeader
          title={`Boas vindas, ${userName}`}
          description="Gerencie o seu atendimento e campanhas com o Blip Go Coex!"
        />
        <WhatsapppConectionCard
          description="Você já pode começar a aproveitar todos os recursos que Blip Coex oferece."
          onActionClick={handleOnClose}
          statusName="Ativo"
          statusType="success"
          title="Seu WhatsApp Business foi conectado com sucesso!"
        ></WhatsapppConectionCard>
      </MainContainer>
    </>
  );
}

export default WhatsappConnectionFinished;
