import { BdsButton, BdsChipTag, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React, { ReactNode } from 'react';
import WhatsappLogo from 'assets/images/whatsapp-icon.svg';
import styles from './WhatsappConnectionContainer.module.scss';
import { useMobileView } from 'contexts/MobileViewContext';

interface IWhatsappConnectionContainerProps {
  children: ReactNode;
  onClose?: () => void;
}

function WhatsappConnectionContainer({ children, onClose }: Readonly<IWhatsappConnectionContainerProps>) {
  const isMobileView = useMobileView();

  return (
    <BdsPaper elevation="none" className={styles['whatsapp-connection-paper']}>
      <BdsGrid direction="column" lg="12" xs="12" padding="x-6">
        <BdsGrid alignItems="center" gap="2" padding="t-3" justifyContent="space-between">
          <BdsGrid gap="2">
            <img className="whatsapp-header-logo" src={WhatsappLogo} alt="whatsapp logo" />
            <BdsGrid alignItems="center" gap="1" direction={!isMobileView ? 'row' : 'column'}>
              <BdsTypo variant="fs-20" bold="bold" margin={false}>
                Conecte ao WhatsApp Business
              </BdsTypo>
              <BdsChipTag color="warning" icon="warning">
                Importante
              </BdsChipTag>
            </BdsGrid>
          </BdsGrid>
          <BdsGrid xs="12" lg="3" md="3" justifyContent="flex-end">
            {!isMobileView && (
              <BdsButton variant="text" color="content" icon-left="close" onBdsClick={onClose} data-testid="close-btn">
                Cancelar conexão
              </BdsButton>
            )}
          </BdsGrid>
        </BdsGrid>
        <BdsGrid padding="y-3" justifyContent="center">
          {children}
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
}

export default WhatsappConnectionContainer;
