import React, { useState, useRef, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { BdsIcon, BdsInput, BdsGrid } from 'blip-ds/dist/blip-ds-react/components';
import { ILogArgs, logger } from 'packs-template-baseweb';
import GptChatBubble from '../GptChatBubble';
import { useAppContext } from 'contexts/AppContext';
import './style.scss';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';

const logArgs: ILogArgs = {
  className: 'ChatComponent',
};

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'assistant';
}

type ChatComponentProps = {
  prompt?: string;
  openModalCallback: () => void;
};

const ChatComponent = ({ prompt, openModalCallback }: ChatComponentProps) => {
  const { botConfiguration } = useAppContext();
  const { chatGpt } = usePlgApi();
  const { createToastError } = useToast();
  const [messageHistory, setMessageHistory] = useState<{ role: 'system' | 'user' | 'assistant'; content: string }[]>(
    [],
  );
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const messagesStartRef = useRef<HTMLDivElement>(null);

  const payloadGpt = async (role: 'system' | 'user' | 'assistant', content: string) => {
    logArgs.methodName = 'payloadGpt';
    const updatedMessageHistory = [
      ...messageHistory,
      {
        role,
        content,
      },
    ];

    setMessageHistory(updatedMessageHistory);
    if (role === 'user') {
      try {
        const gptResponse = await chatGpt(botConfiguration.botId, updatedMessageHistory);

        if (gptResponse) {
          gptResponse.choices.forEach(choice => {
            updatedMessageHistory.push({
              role: 'assistant',
              content: choice.message.content,
            });
          });
          setMessageHistory(updatedMessageHistory);
          return gptResponse;
        }
      } catch (error) {
        const errorMessage = 'Erro ao preparar payload da mensagem';
        const errorPayload = `${errorMessage}, ${error}`;
        logger.error(errorPayload, logArgs);
        createToastError(errorMessage);
      }
    }

    return;
  };

  useEffect(() => {
    if (prompt) {
      payloadGpt('system', prompt);
    }
  }, [prompt]);

  useEffect(() => {
    const systemMessages = messages.filter(message => message.sender === 'assistant');
    if (systemMessages.length === 5) {
      const timeoutId = setTimeout(() => {
        openModalCallback();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [messages, openModalCallback]);

  const handleSendMessage = async () => {
    logArgs.methodName = 'handleSendMessage';
    if (currentMessage.trim() === '') return;

    const newMessage: Message = {
      id: messages.length,
      text: currentMessage.trim(),
      sender: 'user',
    };
    if (prompt) {
      try {
        const gptResponse = await payloadGpt('user', newMessage.text);
        if (gptResponse) {
          setMessages(prevMessages => [...prevMessages, newMessage]);

          const systemMessage: Message = {
            id: messages.length + 1,
            text: gptResponse?.choices[0].message.content,
            sender: 'assistant',
          };
          payloadGpt('assistant', systemMessage.text);
          setMessages(prevMessages => [...prevMessages, systemMessage]);
        }
      } catch (error) {
        const errorMessage = 'Falha ao enviar mensagem';
        const errorPayload = `${errorMessage}, ${error}`;
        logger.error(errorPayload, logArgs);
        createToastError(errorMessage);
      }
    }
    setCurrentMessage('');
  };

  return (
    <>
      <BdsGrid className="chat-grid ">
        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
          <GptChatBubble
            contentHTML={
              '<strong>Teste aqui seu assistente</strong><br>Faça uma pergunta como se fosse um seguidor<br>e veja as respostas do assistente'
            }
            sender={'default'}
          ></GptChatBubble>
          {messages.map(message => (
            <div
              key={message.id}
              style={{
                alignSelf: message.sender === 'user' ? 'flex-start' : 'flex-end',
                margin: '8px',
              }}
            >
              <GptChatBubble contentHTML={message.text} sender={message.sender} />
            </div>
          ))}
          <div ref={messagesStartRef}></div>
        </div>
        <BdsGrid gap="2" className="grid-input-area" direction="column">
          <BdsGrid direction="row" className="chat-input-area">
            <BdsInput
              value={currentMessage}
              onBdsChange={e => setCurrentMessage(e.target.value ?? '')}
              rows={3}
              maxlength={300}
              placeholder="Digite aqui sua mensagem."
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
              onSubmit={handleSendMessage}
              is-textarea={true}
            />
            <IconButton data-testid="send-button" style={{ paddingLeft: '1rem' }} onClick={handleSendMessage}>
              <BdsIcon className="send-icon" theme="solid" size="medium" name="send" />
            </IconButton>
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </>
  );
};

export default ChatComponent;
