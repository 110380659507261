import { BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import styles from './WhatsappConnectionError.module.scss';

import React from 'react';

interface WhatsappConnectionErrorProps {
  errorMessage: string;
  setWabaError: React.Dispatch<React.SetStateAction<boolean>>;
}

const WhatsappConnectionError: React.FC<WhatsappConnectionErrorProps> = ({ errorMessage, setWabaError }) => {
  return (
    <BdsPaper className={styles.error}>
      <BdsGrid padding="2" gap="2" justifyContent="center" alignItems="center">
        <BdsIcon name="error"></BdsIcon>
        <BdsTypo variant="fs-14" bold="bold">
          {errorMessage}
        </BdsTypo>
        <BdsIcon className={styles['click-close']} name="close" onClick={() => setWabaError(false)}></BdsIcon>
      </BdsGrid>
    </BdsPaper>
  );
};

export default WhatsappConnectionError;
