import { BdsBanner, BdsGrid } from 'blip-ds/dist/blip-ds-react';
import MainContainer from 'components/MainContainer';
import MainHeader from 'components/MainHeader';
import MobileToolbar from 'components/MobileToolbar';
import QuickAccessCard from 'components/QuickAccessCard';
import Sidebar from 'components/Sidebar';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import useTrack from 'hooks/useTrack';
import { COEX_CREATE_ACTIVE_MESSAGE, COEX_QUICK_ACCESS_MODULE_SEND } from 'libs/trackingEvents';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function QuickAccess() {
  const { track } = useTrack();
  const { botConfiguration } = useAppContext();
  const navigate = useNavigate();
  const tenantId = process.env.REACT_APP_PACK_TENANT;
  const isProduction = process.env.NODE_ENV === 'production';
  const applicationDetail = isProduction ? 'blip.ai/application/detail' : 'hmg.blip.ai/application/detail';
  const createActiveMessagesUrl = `https://${tenantId}.${applicationDetail}/${botConfiguration.botId}/contents/messagetemplate`;
  const isMobileView = useMobileView();

  const handleAccessCreateMessages = () => {
    track(COEX_CREATE_ACTIVE_MESSAGE);
    window.open(createActiveMessagesUrl, '_blank');
  };

  const handleAccessSendMessages = () => {
    track(COEX_QUICK_ACCESS_MODULE_SEND);
    return navigate('/campaign');
  };

  return (
    <>
      {!isMobileView ? <Sidebar /> : <MobileToolbar />}
      <MainContainer>
        <MainHeader
          title="Acessos rápidos"
          description="Reunimos os principais funcionalidades que você pode precisar acessar."
        />
        <BdsGrid direction="column" gap="4">
          <BdsBanner context="inside">
            O Blip Go! COEX Beta é um produto ainda não lançado, por isso algumas funções serão abertas em uma nova guia
            do seu navegador no portal Blip.
          </BdsBanner>
          <BdsGrid gap="3" direction={!isMobileView ? 'row' : 'column'}>
            <BdsGrid>
              <QuickAccessCard
                actionText="Criar mensagem"
                description="Crie e envie para aprovação do Meta mensagens para sua empresa disparar em massa para seus contatos."
                icon="message-talk"
                onBtnClick={handleAccessCreateMessages}
                title="Crie mensagens para disparo em massa"
              />
            </BdsGrid>
            <BdsGrid>
              <QuickAccessCard
                actionText="Disparar mensagens"
                description="Dispare mensagens em massa para o Whatsapp dos seus contatos."
                icon="message-sent"
                onBtnClick={handleAccessSendMessages}
                title="Dispare mensagens em massa"
              />
            </BdsGrid>
          </BdsGrid>
        </BdsGrid>
      </MainContainer>
    </>
  );
}

export default QuickAccess;
