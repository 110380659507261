import { BdsGrid, BdsPaper } from 'blip-ds/dist/blip-ds-react/components';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import { InstagramSteps } from 'libs/instagramSteps';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InstagramHeader from '../InstagramHeader';
import InstagramActivation from '../InstagramSteps/InstagramActivation';
import InstagramBindPages from '../InstagramSteps/InstagramBindPages';
import InstagramStepFacebookPages from '../InstagramSteps/InstagramStepFacebookPages';
import InstagramSwitchFacebookProfile from '../InstagramSteps/InstagramSwitchFacebookProfile';
import InstagramValidAccountNotFound from '../InstagramSteps/InstagramValidAccountNotFound';
import './style.scss';
import InstagramAllowMessagesAccess from '../InstagramSteps/InstagramAllowMessagesAccess';

function InstagramContainer() {
  const navigate = useNavigate();
  const { getFacebookConnectedData } = useGraphApi();
  const isMobileView = useMobileView();
  const [currentStep, setCurrentStep] = useState(InstagramSteps.FacebookPages);

  const getStepContentByIndex = (index: number) => {
    return steps.find(step => step.index === index)?.content;
  };

  const showHeader = (index: number): boolean => {
    return steps.find(step => step.index === index)?.showHeader!;
  };

  const onBackToStartCallback = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    const fbConnectedData = getFacebookConnectedData();
    if (!fbConnectedData) {
      navigate('/dashboard');
    }
  }, [getFacebookConnectedData, navigate]);

  const steps = [
    {
      index: InstagramSteps.ErrorPage,
      label: 'Conta valida do Instagram não encontrada',
      content: <InstagramValidAccountNotFound backToStartClickCallback={onBackToStartCallback} />,
      showHeader: true,
    },
    {
      index: InstagramSteps.FacebookPages,
      label: 'Lista de páginas comerciais do Facebook',
      content: <InstagramStepFacebookPages setCurrentStep={setCurrentStep} />,
      showHeader: true,
    },
    {
      index: InstagramSteps.InstagramActivation,
      label: 'Confirmação e ativação do bot',
      content: <InstagramActivation setCurrentStep={setCurrentStep} />,
      showHeader: true,
    },
    {
      index: InstagramSteps.InstagramPageNotLinked,
      label: 'Página do Facebook não conectada com Instagram',
      content: <InstagramBindPages setCurrentStep={setCurrentStep} />,
      showHeader: true,
    },
    {
      index: InstagramSteps.InstagramSwitchFacebookProfile,
      label: 'Trocar perfil conectado do facebook',
      content: <InstagramSwitchFacebookProfile setCurrentStep={setCurrentStep} />,
      showHeader: false,
    },
    {
      index: InstagramSteps.InstagramAllowMessagesAccess,
      label: 'Permissão de acesso às mensagens do Instagram não concedida',
      content: <InstagramAllowMessagesAccess setCurrentStep={setCurrentStep} />,
    },
  ];

  return (
    <BdsPaper className="steps-content">
      <BdsGrid className="steps-body" padding="1" direction="column">
        {!isMobileView && showHeader(currentStep) && <InstagramHeader currentStep={currentStep} stepsCount={2} />}
        <BdsGrid justifyContent="center" alignItems="center" gap="3" padding={!isMobileView ? '5' : '3'}>
          {getStepContentByIndex(currentStep)}
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
}

export default InstagramContainer;
