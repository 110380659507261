import React from 'react';
import styles from './CircleNumber.module.scss';

interface CircleNumberProps {
  number: number | string;
}

const CircleNumber: React.FC<CircleNumberProps> = ({ number }) => {
  return <div className={styles['circle-number']}>{number}</div>;
};

export default CircleNumber;
