// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.install-progress-bar {
  width: 100%;
}

.install-info-title {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/PackInstallProgress/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".install-progress-bar {\n  width: 100%;\n}\n\n.install-info-title {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
