import { BdsChipTag, BdsGrid, BdsIcon, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import InsertDataModal from 'components/InsertDataModal';
import PauseAssistantModal from 'components/PauseAssistantModal';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import useAssistantManager from 'hooks/useAssistantManager';
import usePacksApi from 'hooks/usePacksApi';
import usePlgApi from 'hooks/usePlgApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { ContactStatus, HUBSPOT_ASSIST_PARAMETER } from 'libs/hubspotParameters';
import { PAUSE_ASSISTANT, RESUME_ASSISTANT } from 'libs/trackingEvents';
import React, { useRef } from 'react';
import { BotStatus } from 'types/Bot';
import './style.scss';

function DashboardHeader() {
  const { botConfiguration } = useAppContext();
  const { setBotStatus } = usePlgApi();
  const { updateHubspotContact } = usePacksApi();
  const { track } = useTrack();
  const { createToastError } = useToast();
  const insertDataModalRef = useRef<HTMLBdsModalElement | null>(null);
  const pauseAssistantModalRef = useRef<HTMLBdsModalElement | null>(null);
  const { isAssistActive, isAssistPaused, hasSubmittedAnyData } = useAssistantManager({ statusControlEnabled: false });
  const robotColor = isAssistActive
    ? 'success-background'
    : isAssistPaused
    ? 'warning-background'
    : 'system-background';
  const tagText = isAssistActive ? 'ativo' : isAssistPaused ? 'pausado' : 'desativado';

  const pauseAssistant = async () => {
    track(PAUSE_ASSISTANT);
    pauseAssistantModalRef.current?.toggle();
  };

  const reactivateAssistant = async () => {
    if (botConfiguration.status === BotStatus.PausedWithNoData) {
      insertDataModalRef.current?.toggle();
      return;
    }
    try {
      await setBotStatus(BotStatus.Active);
      track(RESUME_ASSISTANT, { status: 'Success' });
      updateHubspotContact(HUBSPOT_ASSIST_PARAMETER, ContactStatus.Active, botConfiguration.botId);
    } catch (error) {
      const err = error as Error;
      track(RESUME_ASSISTANT, { status: 'Error', error: err.message });
      createToastError('Não foi possível realizar esta ação no momento', 'Tente novamente mais tarde');
    }
  };

  return (
    <>
      <BdsPaper elevation="none" className="paper-pattern">
        <BdsGrid direction="column" padding="2">
          <BdsGrid gap="2" margin="none" padding="1">
            <div className={'circle ' + robotColor}>
              <BdsIcon name="robot" size="xxx-large" />
            </div>
            <div>
              <BdsTypo variant="fs-14">Olá, eu sou o seu</BdsTypo>
              <BdsTypo variant="fs-20" bold="bold">
                Assistente Virtual!
              </BdsTypo>
              <BdsChipTag color={isAssistPaused ? 'warning' : isAssistActive ? 'success' : 'outline'}>
                {tagText}
              </BdsChipTag>
            </div>
          </BdsGrid>
          {isAssistActive ? (
            <BdsGrid margin="t-1">
              <MaterialButton
                onClickCallback={pauseAssistant}
                text="Pausar assistente"
                startIconBdsName="pause"
                variant="secondary"
                isMobileView
              />
            </BdsGrid>
          ) : isAssistPaused ? (
            <BdsGrid margin="t-3" direction="column">
              {!hasSubmittedAnyData && (
                <div className="no-data-alert-container">
                  <BdsGrid justifyContent="space-between">
                    <BdsTypo variant="fs-14" italic>
                      Adicione assuntos à base de conhecimento para ativar seu assistente.
                    </BdsTypo>
                    <BdsIcon name="question" theme="solid" />
                  </BdsGrid>
                </div>
              )}
              <MaterialButton
                onClickCallback={reactivateAssistant}
                text="Ativar assistente"
                startIconBdsName="play"
                isMobileView
              />
            </BdsGrid>
          ) : (
            <></>
          )}
        </BdsGrid>
      </BdsPaper>
      {insertDataModalRef.current && <InsertDataModal ref={insertDataModalRef} />}
      {pauseAssistantModalRef.current && <PauseAssistantModal ref={pauseAssistantModalRef} />}
    </>
  );
}

export default DashboardHeader;
