// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-t-1 {
  padding-top: 1rem;
}

.p-t-05 {
  padding-top: 0.5rem;
}

.page-item {
  border-top: 0.0625rem solid var(--color-border-2, rgba(0, 0, 0, 0.16));
  border-bottom: 0.0625rem solid var(--color-border-2, rgba(0, 0, 0, 0.16));
  padding: 1rem 1.5rem 1rem 1.5rem;
  margin-top: -0.0625rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Instagram/InstagramSteps/InstagramStepFacebookPages/style.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,sEAAA;EACA,yEAAA;EACA,gCAAA;EACA,sBAAA;AADF","sourcesContent":["@use '/src/styles/colors';\n\n.p-t-1 {\n  padding-top: 1rem;\n}\n\n.p-t-05 {\n  padding-top: 0.5rem;\n}\n\n.page-item {\n  border-top: 0.0625rem solid colors.$color-border-2;\n  border-bottom: 0.0625rem solid colors.$color-border-2;\n  padding: 1rem 1.5rem 1rem 1.5rem;\n  margin-top: -0.0625rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
