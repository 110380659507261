import { BdsButton, BdsChipTag, BdsGrid, BdsPaper, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import React from 'react';
import WhatsappLogo from 'assets/images/whatsapp-icon.svg';
import CheckIllustration from 'assets/images/circular-check-illustration.svg';
import './styles.scss';
import { useMobileView } from 'contexts/MobileViewContext';
import MaterialButton from 'components/UI/MaterialButton';

interface IWhatsappConnectionCardProps {
  description: string;
  onActionClick: () => void;
  statusName: 'Importante' | 'Ativo';
  statusType: 'success' | 'warning';
  title: string;
}

function WhatsappConnectionCard({
  description,
  onActionClick,
  statusName,
  statusType,
  title,
}: Readonly<IWhatsappConnectionCardProps>) {
  const isMobileView = useMobileView();

  const FinishConnectionButton = () => (
    <BdsButton icon="close" variant="text" color="content" onBdsClick={onActionClick}>
      Fechar
    </BdsButton>
  );

  const StartConnectionButton = () => (
    <MaterialButton onClickCallback={onActionClick} text="Iniciar conexão" isMobileView={isMobileView} />
  );

  const Illustration = () => (
    <img
      className={isMobileView ? 'connection-illustration-mobile' : 'connection-illustration'}
      src={statusType === 'success' ? CheckIllustration : WhatsappLogo}
      alt="WhatsApp activation illustration"
    />
  );

  return (
    <BdsGrid alignItems="center">
      <BdsPaper elevation="none" className={`whatsapp-connection-card ${statusType}-border`}>
        <BdsGrid direction={isMobileView ? 'column' : 'row'} justifyContent="space-between">
          <BdsGrid gap="3" alignItems="center">
            {!isMobileView && <Illustration />}
            <BdsGrid direction="column" gap="2">
              <BdsGrid gap="1" justifyContent={isMobileView ? 'space-between' : 'flex-start'}>
                {!isMobileView && (
                  <BdsTypo variant="fs-20" bold="bold" margin={false}>
                    {title}
                  </BdsTypo>
                )}
                {isMobileView && <Illustration />}
                <BdsChipTag color={statusType} icon={statusType === 'warning' ? statusType : ''}>
                  {statusName}
                </BdsChipTag>
              </BdsGrid>
              {isMobileView && (
                <BdsTypo variant="fs-16" bold="bold" margin={false}>
                  {title}
                </BdsTypo>
              )}
              <BdsTypo variant={isMobileView ? 'fs-14' : 'fs-16'}>{description}</BdsTypo>
            </BdsGrid>
          </BdsGrid>

          <BdsGrid justifyContent="center" lg="2" md="2" alignItems="center" padding="y-1">
            {statusType === 'success' ? <FinishConnectionButton /> : <StartConnectionButton />}
          </BdsGrid>
        </BdsGrid>
      </BdsPaper>
    </BdsGrid>
  );
}

export default WhatsappConnectionCard;
