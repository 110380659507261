import { BdsGrid, BdsIcon, BdsLoadingPage, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import MaterialButton from 'components/UI/MaterialButton';
import { useAppContext } from 'contexts/AppContext';
import { useMobileView } from 'contexts/MobileViewContext';
import useGraphApi from 'hooks/useGraphApi';
import useToast from 'hooks/useToast';
import useTrack from 'hooks/useTrack';
import { FacebookCatalogSteps } from 'libs/facebookCatalogSteps';
import {
  CONNECt_CATALOG_CHANGED_ACCOUNT_CHANGE_CLICK,
  CONNECt_CATALOG_CHANGED_ACCOUNT_FB_LINK,
} from 'libs/trackingEvents';
import React, { useState } from 'react';
import './style.scss';

function FacebookCatalogSwitchProfile({ setCurrentStep }: { setCurrentStep: (step: FacebookCatalogSteps) => void }) {
  const { initFacebookSdk, launchFacebookSignup, logoutFacebook } = useGraphApi();
  const { botConfiguration } = useAppContext();
  const isMobileView = useMobileView();
  const { createToastError } = useToast();
  const { track } = useTrack();
  const [isLoading, setIsLoading] = useState(false);

  const handleConnectFacebook = async () => {
    track(CONNECt_CATALOG_CHANGED_ACCOUNT_CHANGE_CLICK);
    await logoutFacebook();
    await initFacebookSdk(botConfiguration.botId);
    setIsLoading(true);
    try {
      await launchFacebookSignup('catalog_connection', async response => {
        if (!response || response.status !== 'connected') {
          setCurrentStep(FacebookCatalogSteps.FacebookCatalogLogin);
          setIsLoading(false);
          return;
        }
        setCurrentStep(FacebookCatalogSteps.FacebookCatalogConnection);
        setIsLoading(false);
      });
    } catch {
      createToastError('Falha ao conectar com o Facebook', 'Por favor, tente novamente');
      setIsLoading(false);
      return null;
    }
  };

  return (
    <>
      {isLoading && <BdsLoadingPage />}
      <BdsGrid alignItems="center" justifyContent="space-between">
        {!isMobileView && (
          <BdsGrid className="facebookIconContainer" margin="r-5">
            <BdsIcon className="facebookIcon" name="facebook" type="logo" />
          </BdsGrid>
        )}
        <BdsGrid direction="column">
          <BdsGrid margin="t-1">
            <BdsTypo tag="span" variant={!isMobileView ? 'fs-24' : 'fs-20'} bold="bold">
              Para trocar de conta siga os passos abaixo:
            </BdsTypo>
          </BdsGrid>
          <BdsGrid direction="column" gap="1" margin={!isMobileView ? 't-4' : 't-3'}>
            <BdsTypo tag="p" lineHeight="plus">
              <b>1</b> - Acesse&nbsp;
              <a
                onClick={() => track(CONNECt_CATALOG_CHANGED_ACCOUNT_FB_LINK)}
                href="https://www.facebook.com/"
                target="_blank"
              >
                www.facebook.com
              </a>
            </BdsTypo>
            <BdsTypo tag="p" lineHeight="plus">
              <b>2</b> - Saia da conta atual
            </BdsTypo>
            <BdsTypo tag="p" lineHeight="plus">
              <b>3</b> - Faça login com a conta correta no próprio site
            </BdsTypo>
            <BdsTypo tag="p" lineHeight="plus">
              <b>4</b> - Volte aqui para iniciar a conexão.
            </BdsTypo>
          </BdsGrid>
          <BdsGrid margin="t-4">
            <MaterialButton
              text="Conectar Facebook"
              onClickCallback={handleConnectFacebook}
              variant="primary"
              startIconBdsName="facebook"
              isMobileView={isMobileView}
            />
          </BdsGrid>
        </BdsGrid>
      </BdsGrid>
    </>
  );
}

export default FacebookCatalogSwitchProfile;
