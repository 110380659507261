import { BdsGrid, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import React from 'react';
import './style.scss';

type FaqBubbleProps = {
  contentHTML: string;
};

function FaqChatBubble({ contentHTML }: FaqBubbleProps) {
  return (
    <BdsGrid className="faq-bubble">
      <BdsTypo variant="fs-14">
        <div dangerouslySetInnerHTML={{ __html: contentHTML }}></div>
      </BdsTypo>
    </BdsGrid>
  );
}

export default FaqChatBubble;
