import { BdsButton, BdsGrid, BdsIllustration, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import MobileToolbar from 'components/MobileToolbar';
import Sidebar from 'components/Sidebar';
import { useMobileView } from 'contexts/MobileViewContext';
import React from 'react';
import styles from './WhatsAppConnect.module.scss';
import MainHeader from 'components/MainHeader';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import useTrack from 'hooks/useTrack';
import {
  COEX_HELP_CLICKED,
  COEX_META_CONNECTION_COMPLETED_CLOSE,
  COEX_META_WHATSAPP_OPEN_EMBEDED_SIGNUP,
} from 'libs/trackingEvents';
import WhatsappConnectionContainer from 'components/WhatsappConnectionContainer/WhatsappConnectionContainer';
import WhatsappConnectionCarroussel from 'components/WhatsappConnectionCarrousel/WhatsappConnectionCarrousel';
import useGraphApi from 'hooks/useGraphApi';
import { useAppContext } from 'contexts/AppContext';
import { Divider } from '@mui/material';
import MaterialButton from 'components/UI/MaterialButton';
import WhatsappConnectionError from 'components/WhatsAppConnectionError';
import { useAtom, useSetAtom } from 'jotai';
import { facebookAuthResponse, errorMessageMeta, isMetaError } from 'atoms/AppAtoms';
import { MetaAuthResponse } from 'types/Whatsapp';
import MainContainer from 'components/MainContainer';

function WhatsAppConnect() {
  const isMobileView = useMobileView();
  const { userData } = useAuth();
  const { botConfiguration } = useAppContext();
  const setFacebookAuth = useSetAtom(facebookAuthResponse);
  const { launchFacebookSignup, initFacebookSdk } = useGraphApi();
  const userName = userData?.profile.FirstName ?? userData?.profile.FullName;
  const navigate = useNavigate();
  const { track } = useTrack();
  const [error, setError] = useAtom(isMetaError);
  const [errorMessage, setErrorMessage] = useAtom(errorMessageMeta);

  const handleOnClose = () => {
    track(COEX_META_CONNECTION_COMPLETED_CLOSE);
    navigate('/');
  };

  const handleLinkClickSuport = () => {
    track(COEX_HELP_CLICKED, { screenName: location.pathname });
    const coexLink =
      'https://api.whatsapp.com/send/?phone=553192670115&text=Ol%C3%A1,%20estou%20com%20d%C3%BAvidas%20sobre%20o%20Blip%20Go!%20COEX%20Beta';
    window.open(coexLink, '_blank');
  };

  const handleLoginFacebookClick = async () => {
    track(COEX_META_WHATSAPP_OPEN_EMBEDED_SIGNUP);
    await initFacebookSdk(botConfiguration.botId);
    await launchFacebookSignup('whatsapp_connection', async response => {
      if (!response || response.status !== 'connected') {
        setError(true);
        setErrorMessage('O Facebook signup foi fechado ou ocorreu um erro na autenticação');
        return;
      } else {
        const authResponse = response.authResponse as MetaAuthResponse;
        setFacebookAuth(authResponse);
        navigate('/whatsapp/number-connect');
      }
    }).catch(() => {
      return null;
    });
  };

  return (
    <>
      {isMobileView ? <MobileToolbar /> : <Sidebar />}
      <MainContainer>
        <MainHeader title={`Boas vindas, ${userName}`} description="" />
        <WhatsappConnectionContainer onClose={handleOnClose}>
          <BdsGrid className={styles['whatsapp-connect-container']} xs="11" lg="11" md="11" gap="3" direction="column">
            <BdsGrid direction="row" gap="3" alignItems="center" padding="2">
              <BdsIllustration name="blip-whatsapp" type="logo-integration"></BdsIllustration>
              <BdsGrid direction="column" gap="2">
                <BdsGrid direction="column">
                  <BdsTypo variant="fs-20" bold="bold" line-height="none">
                    Conclua a conexão com a sua conta do Facebook
                  </BdsTypo>
                  <BdsTypo>
                    Será aberto uma nova janela onde você deve fazer login com o Facebook e seguir as etapas para
                    autorização da conexão com o Whatsapp Business.
                  </BdsTypo>
                </BdsGrid>
                <BdsButton data-testid="facebook-login-button" arrow={true} onBdsClick={handleLoginFacebookClick}>
                  Abrir janela de conexão
                </BdsButton>
              </BdsGrid>
            </BdsGrid>
            <Divider className={styles.divider} />
            {error && (
              <WhatsappConnectionError errorMessage={errorMessage} setWabaError={setError}></WhatsappConnectionError>
            )}

            <BdsGrid direction="column" gap="2">
              <BdsGrid direction="column">
                <BdsTypo variant="fs-20" bold="bold">
                  Siga as instruções para conectar
                </BdsTypo>
                <BdsTypo line-height="none">
                  Na janela do Facebook que abriu, siga as etapas abaixo para concluir a conexão.
                </BdsTypo>
              </BdsGrid>
              <WhatsappConnectionCarroussel />
            </BdsGrid>
            <Divider />
            <BdsGrid direction="row" justifyContent="space-between">
              <BdsTypo variant="fs-16" bold="bold">
                Está tendo dificuldades para conectar? Nosso time de especialistas pode te ajudar.
              </BdsTypo>
              <MaterialButton
                onClickCallback={handleLinkClickSuport}
                text="Solicitar suporte"
                startIconBdsName="agent"
                variant="secondary"
              />
            </BdsGrid>
          </BdsGrid>
        </WhatsappConnectionContainer>
      </MainContainer>
    </>
  );
}

export default WhatsAppConnect;
