import { BdsGrid, BdsInput, BdsRadio, BdsRadioGroup } from 'blip-ds/dist/blip-ds-react/components';
import FaqChatBubble from 'components/FaqWizard/FaqChatBubble';
import MaterialButton from 'components/UI/MaterialButton';
import { useMobileView } from 'contexts/MobileViewContext';
import useToast from 'hooks/useToast';
import React, { useEffect, useRef, useState } from 'react';
import { Faq, WizardTempFaq } from 'types/Faq';

type FaqInputStoreAddressProps = {
  tempFaq: WizardTempFaq | null;
  onNextClickCallback: (faq: WizardTempFaq | null) => void;
};

function FaqInputStoreAddress({ tempFaq, onNextClickCallback }: FaqInputStoreAddressProps) {
  const isMobileView = useMobileView();
  const { createToastWarning } = useToast();
  const [showInputText, setShowInputText] = useState(false);
  const [selectedOption, setSelectedOption] = useState(tempFaq?.selectedOption || '');
  const [isFaqValid, setIsFaqValid] = useState(false);
  const inputAnswerRef = useRef<HTMLBdsInputElement>(null);

  const defaultQuestion = 'Qual é o endereço da sua loja?';

  const handleNextClick = () => {
    let tempFaq = { selectedOption } as WizardTempFaq;
    const randomId = crypto.randomUUID();
    switch (selectedOption) {
      case 'yes':
        const address = inputAnswerRef.current?.value as string;
        tempFaq.faq = {
          id: randomId,
          question: defaultQuestion,
          answer: address,
        } as Faq;
        onNextClickCallback(tempFaq);
        break;
      case 'no':
        tempFaq.faq = {
          id: randomId,
          question: defaultQuestion,
          answer: 'Não temos loja física.',
        } as Faq;
        onNextClickCallback(tempFaq);
        break;
      default:
        createToastWarning('Opção inválida', 'Por favor, selecione uma opção para continuar');
        break;
    }
  };

  const validateInputs = async () => {
    const isValid = selectedOption === 'no' || (await inputAnswerRef.current?.isValid());
    setIsFaqValid(isValid || false);
  };

  const clearForm = () => {
    tempFaq?.faq && (tempFaq.faq.answer = '');
    inputAnswerRef?.current?.clear();
  };

  const handleRadioChange = (ev: any) => {
    clearForm();
    const option = ev?.target?.value;
    setSelectedOption(option);
    if (option === 'yes') {
      setShowInputText(true);
      setIsFaqValid(false);
    } else {
      setShowInputText(false);
      setIsFaqValid(true);
    }
  };

  useEffect(() => {
    setShowInputText(selectedOption === 'yes');
    setIsFaqValid(selectedOption === 'no' || !!tempFaq?.faq?.answer);
  }, [selectedOption, tempFaq]);

  return (
    <>
      <FaqChatBubble contentHTML={'Primeiro, você tem <b>loja física</b>?'} />
      <BdsGrid padding="l-1">
        <BdsRadioGroup id="rbg-options">
          <BdsGrid direction="column" gap="1">
            <BdsRadio data-testid="rb-yes" value="yes" label="Sim" onBdsClickChange={handleRadioChange} />
            <BdsRadio data-testid="rb-no" value="no" label="Não" onBdsClickChange={handleRadioChange} />
          </BdsGrid>
        </BdsRadioGroup>
      </BdsGrid>
      {showInputText && (
        <>
          <FaqChatBubble contentHTML={'Qual é o endereço?'} />
          <BdsGrid padding="l-1" xxs="12">
            <BdsInput
              id="answer-input"
              ref={inputAnswerRef}
              onBdsChange={validateInputs}
              isTextarea
              required
              counterLength
              rows={3}
              maxlength={300}
              placeholder="Digite aqui o endereço completo da sua loja! Pode colocar algum ponto de referência também :)"
              requiredErrorMessage="Este campo é obrigatório"
            />
          </BdsGrid>
        </>
      )}
      <BdsGrid xxs="12" margin="t-4" gap="2" direction="column-reverse">
        <MaterialButton
          onClickCallback={handleNextClick}
          text="Confirmar e continuar"
          variant="primary"
          endIconBdsName="arrow-right"
          isMobileView={isMobileView}
          disabled={!isFaqValid}
        ></MaterialButton>
      </BdsGrid>
    </>
  );
}

export default FaqInputStoreAddress;
